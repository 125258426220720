import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Feature } from "@app/types";

import { systemConstants } from "../constants";

export const featuresService = createApi({
  reducerPath: "featuresApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Feature"],
  endpoints: builder => ({
    getFeatures: builder.query<Feature[], void>({
      query: () => "/api/features",
      keepUnusedDataFor: 600, // keep for 10 minutes
      providesTags: ["Feature"],
      transformResponse: (result): Feature[] =>
        result.reduce(
          (acc, { name, enabled }) => ({ ...acc, [name]: enabled }),
          {}
        )
    })
  })
});

export const { useGetFeaturesQuery } = featuresService;
