import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { isBoolean, isEqual } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import dateFormatter from "@shared/helpers/dateHelper";
import {
  useLocaleDate,
  useSaveAnswerMutation,
  useUpdateDeleteAnswers
} from "@shared/hooks";
import { useGetSupportedMimesQuery } from "@shared/services/documentMimesService";

import ions from "@ions";

import { isExcel } from "@app/helpers";
import { smartFormHelper, smartFormResponseType } from "@app/helpers/smartForm";
import {
  formatDocumentAnswersPayload,
  formatSmartFormPayload,
  openSmartFormWebSheet
} from "@app/helpers/smartForm/smartFormHelper";

import { Attachment } from "@atoms/Attachment";
import { Button, ButtonSize, ButtonVariant } from "@atoms/Button";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import ProgressSpinner from "@components/atoms/ProgressSpinner";
import ModalForm from "@components/molecules/ModalForm";
import SmartFormEntityProgress from "@components/molecules/SmartFormEntityProgress";

import FileAttachmentList from "../FileAttachmentList/FileAttachmentList";
import UploadFileButton from "../UploadFileButton";
import SmartFormEntitiesFooter from "./SmartFormEntitiesFooter";
import "./SmartFormModal.scss";
import SmartFormModalTable from "./SmartFormModalTable";
import WebSheetStatusIcon from "./WebsheetStatusIcon/WebSheetStatusIcon";

const addFilesState = systemConstants.addFiles.state;
const ERROR_MESSAGE_KEYS = {
  MISSING_RESPONSE:
    "requests:requests.ui.smartForm.modal.answerPerEntity.error.missingResponse",
  MULTIPLE_ENTITY:
    "requests:requests.ui.smartForm.modal.errorMessage.duplicateEntities",
  WEBSHEET_CREATED:
    "requests:requests.ui.smartForm.modal.errorMessage.websheetCreated",
  CHANGED_ENTITY:
    "requests:requests.ui.smartForm.modal.errorMessage.changedEntities",
  OUT_OF_RANGE:
    "requests:requests.ui.smartForm.modal.errorMessage.valueOutOfRange",
  VALUE_TOO_HIGH:
    "requests:requests.ui.smartForm.modal.errorMessage.valueTooHigh",
  VALUE_TOO_LOW: "requests:requests.ui.smartForm.modal.errorMessage.valueTooLow"
};

const SmartFormModal = props => {
  const { t } = useTranslation();
  const {
    question,
    handleClose,
    queryId,
    queryConfig,
    projectId,
    answer,
    relevantEntitiesById,
    setIsOTUpdate,
    disableAnswerSubmissions,
    handleFileDownload
  } = props;
  const { responseType, questionId, relevantEntities } = question ?? {};
  const isDocumentQuestion =
    responseType === smartFormResponseType.DOCUMENT ||
    responseType === smartFormResponseType.WEBSHEET;
  const {
    error: deletedAnswerError,
    removeDeleted,
    deletedAnswers
  } = useUpdateDeleteAnswers(queryId, questionId, isDocumentQuestion);
  const {
    locale,
    options: { numericFormat }
  } = useLocaleDate();

  const { data: supportedDocumentMimes } = useGetSupportedMimesQuery({
    type: systemConstants.mimeDocumentType.document
  });
  const [removedFile, setRemovedFile] = useState("");
  const [uploadState, setUploadState] = useState(addFilesState.add);
  const [showDeletedDocuments, setShowDeletedDocuments] = useState(false);

  const [isCreateWebSheetDisabled, setIsCreateWebsheetDisabled] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [entityErrorMessage, setEntityErrorMessage] = useState("");
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [saveAnswer, { error, isSuccess: isSaveAnswerSuccess }] =
    useSaveAnswerMutation();
  const [createWebSheet, { error: webSheetError }] = useSaveAnswerMutation();
  const [formData, setFormData] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [newRevisionFiles, setNewRevisionFiles] = useState([]);
  const [replacedFiles, setReplacedFiles] = useState({});
  const [dirtyEntities, setDirtyEntities] = useState(false);
  const [webSheetCreated, setWebSheetCreated] = useState(false);
  const [unsavedRemovedFile, setUnsavedRemovedFile] = useState(() => new Set());
  const [unsavedNewFiles, setUnsavedNewFiles] = useState([]);
  const [localDeletedAnswers, setLocalDeletedAnswers] = useState([]);
  const didMount = useRef(false);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [currentUploadingFile, setCurrentUploadingFile] = useState({
    isUploading: false,
    uploadProgress: 0,
    index: 0
  });

  const previousAnswer = useMemo(() => {
    // documents returns [eid]: []
    // others returns [eid]: value
    const prevAnswer = {};
    answer.forEach(a => {
      if (isDocumentQuestion) {
        a.entities?.forEach(e => {
          prevAnswer[e] ??= [];
          prevAnswer[e].push(a.value.id);
        });
      } else {
        a.entities?.forEach(e => {
          prevAnswer[e] = a.value;
        });
      }
    });
    return prevAnswer;
  }, [answer, isDocumentQuestion]);

  useEffect(() => {
    setLocalDeletedAnswers(
      structuredClone(
        deletedAnswers
          ?.map(d => {
            return {
              ...d,
              value: {
                ...d.value,
                locked: d.locked
              }
            };
          })
          .sort((a, b) => Number(a.locked) - Number(b.locked))
      )
    );
  }, [deletedAnswers]);

  const questionRelevantEntitiesById = useMemo(() => {
    if (!relevantEntities?.length) {
      return relevantEntitiesById;
    }
    return Object.keys(relevantEntitiesById)
      ?.filter(key => relevantEntities.includes(key))
      ?.reduce((cur, key) => {
        return Object.assign(cur, { [key]: relevantEntitiesById[key] });
      }, {});
  }, [relevantEntities, relevantEntitiesById]);

  useEffect(() => {
    setErrorMessage(error?.data?.key ?? error?.data?.message ?? error?.error);
  }, [error]);

  useEffect(() => {
    setErrorMessage(deletedAnswerError);
  }, [deletedAnswerError]);

  useEffect(() => {
    setErrorMessage(
      webSheetError?.data?.key ??
        webSheetError?.data?.message ??
        webSheetError?.error
    );
  }, [webSheetError]);

  const closeModal = useCallback(() => {
    setFormData([]);
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (isSaveAnswerSuccess) {
      setIsOTUpdate(true);
      closeModal();
    }
  }, [closeModal, isSaveAnswerSuccess, setIsOTUpdate]);

  useEffect(() => {
    //we shouldn't create more websheet than remaining unassigned entities
    const data = formData ?? [];
    const documentCount = data.length;
    const entitiesCount = Object.values(questionRelevantEntitiesById).length;
    const assigned = data.flatMap(a => a.entities);
    const assignedCount = assigned.length;

    setIsCreateWebsheetDisabled(
      entitiesCount != 0 &&
        (documentCount >= entitiesCount || assignedCount >= entitiesCount)
    );
  }, [formData, questionRelevantEntitiesById]);

  useEffect(() => {
    if (localDeletedAnswers?.length === 0) {
      setShowDeletedDocuments(false);
    }
  }, [localDeletedAnswers]);

  const isUnsaved = useCallback(
    ans => {
      function finder(answerType) {
        if (answerType === "string" || answerType === "boolean") {
          return a => a.value === ans.value;
        } else {
          return a => a?.value?.id === ans?.value?.id;
        }
      }

      const savedAnswer = answer?.find(finder(typeof ans?.value));
      if (!savedAnswer) {
        return true;
      }

      const sameEntities = isEqual(
        ans?.entities
          ?.map(entity => entity.value)
          ?.toSorted((a, b) => a.localeCompare(b)),
        savedAnswer?.entities?.toSorted((a, b) => a.localeCompare(b))
      );
      return !sameEntities;
    },
    [answer]
  );

  function docSort(a, b) {
    if (a.order && b.order) {
      return a.order - b.order;
    }
    if (a.value instanceof File && b.value instanceof Object) {
      return -1;
    }
    if (a.value instanceof Object && b.value instanceof File) {
      return 1;
    }
    return 0;
  }

  const formattedEntities = useMemo(
    () =>
      Object.entries(questionRelevantEntitiesById).map(([value, name]) => {
        return {
          name,
          value
        };
      }),
    [questionRelevantEntitiesById]
  );

  const onDropFiles = useCallback(
    acceptedFiles => {
      setRemovedFile("");
      if (Object.keys(acceptedFiles).length) {
        let newUnsavedRemovedFiles = new Set(unsavedNewFiles);
        Object.values(acceptedFiles).forEach(f => {
          if (f.isRevision) {
            setReplacedFiles(prev => {
              prev[f.id] = f.originalName;
              return prev;
            });
          }
          if (newUnsavedRemovedFiles.has(f.name)) {
            newUnsavedRemovedFiles.delete(f.name);
          }
        });
        const incomingFiles = new Set(Object.keys(acceptedFiles));
        unsavedNewFiles.forEach(file => {
          if (!incomingFiles.has(file.value.name)) {
            file.value.isRecovered = true;
            file.value.entities = file.entities;
            setNewRevisionFiles(prevState => {
              return [...prevState, file.value];
            });
          }
        });
        if (uploadState === "upload") {
          const uploadingFile = Object.values(acceptedFiles).find(
            af => af.state === "uploading"
          );

          const uploadedFs = Object.values(acceptedFiles)
            .filter(af => af.state === "uploaded")
            .map(f => f.name);

          if (!uploadedFiles || uploadedFs.length !== uploadedFiles.length) {
            setUploadedFiles(uploadedFs);
          }

          setCurrentUploadingFile({
            isUploading: true,
            uploadProgress: uploadingFile?.uploadProgress,
            index: formData.findIndex(
              f => f.value?.name === uploadingFile?.name
            )
          });
        } else {
          setFormData(prev => {
            const restoredFiles = prev
              .filter(data => data.value.isRestored)
              .map(f => {
                f.unsaved = true;
                return f;
              });
            const newUploadedFiles = Object.values(acceptedFiles)
              .filter(
                f =>
                  !newUnsavedRemovedFiles.has(f.name) &&
                  !newUnsavedRemovedFiles.has(f.id)
              )
              .filter(f => !f.isDeleted)
              .map(f => {
                const currentIndex = f.isRevision
                  ? prev.findIndex(p => p.value?.id === f.id)
                  : prev.findIndex(p => p.value?.name === f.name);
                const currentAnswer = prev[currentIndex];
                const isReplaced = replacedFiles[f.id] && !f.isRevision;
                if (f.isRecovered) {
                  const recoveredFile = unsavedNewFiles.find(
                    file => file.value.name === f.name
                  );
                  f.entities = recoveredFile.entities;
                }
                const defaultEntities =
                  formattedEntities?.length === 1 ? formattedEntities : [];
                return {
                  value: f,
                  responseType: f.responseType,
                  entities:
                    currentAnswer?.entities ??
                    f.entities ??
                    defaultEntities ??
                    [],
                  unsaved: isUnsaved(currentAnswer) || f.isRevision,
                  locked: f.locked || false,
                  isHidden: isReplaced,
                  order: currentIndex,
                  errorCheckStatus: currentAnswer?.errorCheckStatus
                };
              })
              .sort(docSort);
            return [...newUploadedFiles, ...restoredFiles];
          });
        }
        setUnsavedRemovedFile(newUnsavedRemovedFiles);
      } else if (unsavedNewFiles.length) {
        const files = unsavedNewFiles.map(f => {
          f.value.entities = f.entities;
          return f.value;
        });
        setNewRevisionFiles(prevState => [...prevState, ...files]);
      }
    },
    [
      unsavedNewFiles,
      uploadState,
      uploadedFiles,
      formData,
      replacedFiles,
      isUnsaved,
      formattedEntities
    ]
  );

  const handleUploadComplete = useCallback(
    uploadedFiles => {
      setUploadState(addFilesState.finished);
      const formatFile = (file, isReplaced) => ({
        documentId: file.id ?? null,
        filePathId: file.filePathId,
        name: file.isRevision ? replacedFiles[file.id] : file.name,
        projectId,
        isDeleted: file.isDeleted || unsavedRemovedFile.has(file.id) || false,
        isNew: file.isNew || false,
        isRevision: file.isRevision || false,
        isRestored: file.isRestored || false,
        isReplaced: isReplaced || false,
        responseType: file.responseType,
        documentRevisions: file.documentRevisions,
        entities: file.entities,
        complete: file.constructor.name !== "File" ? file.complete : true,
        updatedBy: file?.updatedBy
      });
      const restoredFiles = formData
        .filter(f => f.value.isRestored)
        .map(f => {
          f.value.entities = f.entities.map(e => e.value);
          return formatFile(f.value, false);
        });
      formData
        .filter(f => !f.value.isRestored)
        .forEach(f => {
          const selectedEntities = f.entities.map(e => e.value);
          uploadedFiles[f.value.name].entities = selectedEntities;
          uploadedFiles[f.value.name].responseType = f.responseType;
        });
      answer
        ?.filter(f => !f.value.isRestored)
        .forEach(file => {
          uploadedFiles[file.value.name].complete = file?.complete;
        });

      let newFiles = [];

      if (uploadedFiles && Object.keys(uploadedFiles).length > 0) {
        newFiles = Object.keys(uploadedFiles).map(key => {
          const file = uploadedFiles[key];
          const isReplaced = replacedFiles[file.id] && !file.isRevision;
          if (file.isRestore) {
            return;
          }
          return formatFile(file, isReplaced);
        });
      }
      newFiles = [...newFiles, ...restoredFiles];
      const data = newFiles.map(f => ({ value: f }));

      // attaches entity change information to each file
      const dataWithEntityChanges = formatDocumentAnswersPayload(
        previousAnswer,
        data
      );

      saveAnswer({ answer: dataWithEntityChanges, queryId, questionId });
    },
    [
      formData,
      answer,
      previousAnswer,
      saveAnswer,
      queryId,
      questionId,
      replacedFiles,
      projectId,
      unsavedRemovedFile
    ]
  );

  const onClickRemove = useCallback(
    (value, index) => {
      const { name, id, isRevision, isRestored } = value;
      if (isDocumentQuestion) {
        if (isRevision) {
          setReplacedFiles(prev => {
            delete prev[id];
            return prev;
          });
        }
        if (isRestored) {
          const restoreFile = structuredClone(
            deletedAnswers.find(d => d.value.id === id)
          );
          if (restoreFile) {
            setLocalDeletedAnswers(prev => [].concat(prev, restoreFile));
          }
        } else {
          setUnsavedRemovedFile(prev => new Set(prev).add(id ?? name));
          setUnsavedNewFiles(prev => prev.filter(f => f.value.name !== name));
        }
      }
      setFormData(prev => prev.filter((_, i) => i !== index));
      setRemovedFile(name);
    },
    [deletedAnswers, isDocumentQuestion]
  );

  const populateEntities = useCallback(
    entityIds => {
      if (!entityIds) {
        return [];
      }
      const answerEntitiesSet = new Set(entityIds);
      return (
        formattedEntities.filter(e => answerEntitiesSet.has(e.value)) ?? []
      );
    },
    [formattedEntities]
  );

  const getAnswerData = useCallback(
    answer => {
      return answer?.map(ans => ({
        ...ans,
        entities: populateEntities(ans.entities),
        unsaved: false
      }));
    },
    [populateEntities]
  );

  const onCreateWebSheet = useCallback(() => {
    if (dirtyEntities) {
      setErrorMessage(t(ERROR_MESSAGE_KEYS.CHANGED_ENTITY));
      return;
    }

    setIsCreateWebsheetDisabled(true);
    createWebSheet({
      queryId,
      questionId,
      answer: { value: "websheet" }
    })
      .unwrap()
      .then(data => {
        setWebSheetCreated(true);
        const updatedAnswer = data?.answers[questionId];
        const answerData = getAnswerData(updatedAnswer);
        setExistingFiles(
          structuredClone(
            updatedAnswer?.map(a => {
              return {
                ...a.value,
                locked: a.locked,
                isRestored: a.value.isRestored,
                isRevision: false,
                isDeleted: a.value.isDeleted,
                updatedBy: a.updatedBy
              };
            })
          )
        );
        setFormData(answerData);
      });
  }, [createWebSheet, dirtyEntities, getAnswerData, queryId, questionId, t]);

  const getDuplicates = arr =>
    arr.filter((elem, index, arr) => arr.indexOf(elem) !== index);

  const checkDuplicateEntities = useCallback(
    taggedEntities => {
      const duplicateEntities = getDuplicates(taggedEntities);
      if (duplicateEntities.length) {
        setEntityErrorMessage(
          t(ERROR_MESSAGE_KEYS.MULTIPLE_ENTITY, {
            entityNames: duplicateEntities
              .map(e => questionRelevantEntitiesById[e])
              .join(", "),
            context: question.responseType
          })
        );
        return true;
      }
      return false;
    },
    [t, question.responseType, questionRelevantEntitiesById]
  );

  const checkMissingValues = useCallback(
    newData => {
      for (const d of newData) {
        if (!d.value && !isBoolean(d.value)) {
          setErrorMessage(t(ERROR_MESSAGE_KEYS.MISSING_RESPONSE));
          return true;
        }
      }
      return false;
    },
    [t]
  );

  const checkNumberInRange = useCallback(
    newData => {
      for (const d of newData) {
        if (
          d.unsaved &&
          (question.min !== undefined || question.max !== undefined)
        ) {
          if (
            !smartFormHelper.isNumberInRange({
              value: d.value,
              min: question.min,
              max: question.max
            })
          ) {
            let errorKey = ERROR_MESSAGE_KEYS.OUT_OF_RANGE;

            if (question.min === undefined) {
              errorKey = ERROR_MESSAGE_KEYS.VALUE_TOO_HIGH;
            } else if (question.max === undefined) {
              errorKey = ERROR_MESSAGE_KEYS.VALUE_TOO_LOW;
            }

            setInputErrorMessage(
              t(errorKey, {
                min: question.min,
                max: question.max
              })
            );
            return true;
          }
        }
      }
      return false;
    },
    [question.max, t, question.min]
  );

  const checkErrors = useCallback(
    newData => {
      const taggedEntities = newData?.flatMap(
        d => d.entities?.map(e => e.value) ?? []
      );

      if (
        checkDuplicateEntities(taggedEntities) ||
        checkMissingValues(newData) ||
        checkNumberInRange(newData)
      ) {
        return true;
      }

      setErrorMessage("");
      return false;
    },
    [checkDuplicateEntities, checkMissingValues, checkNumberInRange]
  );

  const handleCancel = useCallback(() => {
    if (webSheetCreated) {
      setShowDeletedDocuments(false);
      setErrorMessage(t(ERROR_MESSAGE_KEYS.WEBSHEET_CREATED));
      return;
    }
    closeModal();
  }, [closeModal, t, webSheetCreated]);

  const validateAtMostOneAnswerPerEntity = useCallback(() => {
    const entitiesMapToDocumentCount = formData.reduce((acc, { entities }) => {
      entities.forEach(({ value }) => {
        if (acc[value]) {
          acc[value]++;
        } else {
          acc[value] = 1;
        }
      });
      return acc;
    }, {});

    const isOverAllocated = Object.values(entitiesMapToDocumentCount).some(
      e => e != 1
    );
    const isRevision = newRevisionFiles.some(data => data.isRevision);
    if (isOverAllocated && !isRevision) {
      setErrorMessage(t(ERROR_MESSAGE_KEYS.MULTIPLE_ENTITY));
      return isOverAllocated;
    }
    return false;
  }, [formData, newRevisionFiles, t]);

  const validateALeastOneEntityPerAnswer = useCallback(() => {
    const numberOfMissing = formattedEntities?.length
      ? formData.filter(ans => !ans.entities || ans.entities.length === 0)
          .length
      : 0;
    if (numberOfMissing) {
      setEntityErrorMessage(
        t("requests:requests.ui.smartForm.modal.errorMessage.entities", {
          count: numberOfMissing
        })
      );
      return false;
    }
    return true;
  }, [formData, formattedEntities?.length, t]);

  const handleSubmit = useCallback(() => {
    if (!validateALeastOneEntityPerAnswer()) {
      return;
    }

    setErrorMessage("");
    const questionId = question?.questionId;
    const finalAnswer = {};
    switch (responseType) {
      case smartFormResponseType.BOOLEAN:
      case smartFormResponseType.NUMBER:
      case smartFormResponseType.TEXT:
      case smartFormResponseType.DATE:
      case smartFormResponseType.SINGLE_CHOICE:
        if (checkErrors(formData)) {
          return;
        }
        setErrorMessage("");
        formData.forEach(({ entities, value }) => {
          entities?.forEach(e => {
            finalAnswer[e.value] = value;
          });
        });
        saveAnswer({
          answer: formatSmartFormPayload(previousAnswer, finalAnswer),
          queryId,
          questionId
        });
        break;
      case smartFormResponseType.WEBSHEET:
        if (validateAtMostOneAnswerPerEntity()) {
          return;
        }
        setUploadState(addFilesState.upload);
        return;
      case smartFormResponseType.DOCUMENT:
        setUploadState(addFilesState.upload);
        //waiting for file to be uploaded, answer gets saved at handleUploadComplete
        return;
      default:
        break;
    }
  }, [
    question?.questionId,
    responseType,
    checkErrors,
    formData,
    saveAnswer,
    queryId,
    validateALeastOneEntityPerAnswer,
    validateAtMostOneAnswerPerEntity,
    previousAnswer
  ]);

  const renderTitleContent = useCallback(() => {
    return (
      <>
        <span className="smart-form-modal__header__question">
          {question.text}
        </span>
      </>
    );
  }, [question.text]);

  const getTitle = () => {
    if (showDeletedDocuments) {
      return t("requests:requests.ui.smartForm.modal.deletedDocuments.label");
    }
    switch (question.responseType) {
      case smartFormResponseType.DOCUMENT:
        return t("requests:requests.ui.smartForm.modal.uploadDocuments.label");
      case smartFormResponseType.WEBSHEET:
        return t(
          "requests:requests.ui.smartForm.modal.fillWebsheet.modal.title"
        );
      case smartFormResponseType.NUMBER:
      case smartFormResponseType.BOOLEAN:
      case smartFormResponseType.TEXT:
      case smartFormResponseType.DATE:
      case smartFormResponseType.SINGLE_CHOICE:
      default:
        return t("requests:requests.ui.smartForm.answerPerEntity.label");
    }
  };

  const toggleDeletedDocuments = () => {
    setErrorMessage("");
    setShowDeletedDocuments(prev => {
      return !prev;
    });
  };

  const getAction = () => {
    const responseTypesWithAction = [
      smartFormResponseType.DOCUMENT,
      smartFormResponseType.WEBSHEET
    ];
    const responseCheck = responseTypesWithAction.includes(
      question.responseType
    );
    if (!localDeletedAnswers?.length || !responseCheck) {
      return null;
    }

    const label = showDeletedDocuments
      ? t("ui.documents.binText_activeDocument")
      : t("ui.documents.binText_archivedDocument");
    return (
      <Button
        label={label}
        variant={
          ions.components?.templates?.content_container?.header?.button_variant
        }
        size={ButtonSize.MINIMAL}
        onClick={toggleDeletedDocuments}
        disabled={false}
      />
    );
  };

  const renderHeader = () => {
    return {
      title: getTitle(),
      content: renderTitleContent(),
      colouredBg: true,
      expandable: true,
      action: getAction()
    };
  };

  const handleReplaceFile = useCallback((replaceFile, originalFile) => {
    const newFile = replaceFile;
    newFile.id = originalFile.id;
    newFile.isRevision = true;
    newFile.revisionCount = +originalFile.documentRevisions[0]?.revision + 1;
    newFile.originalName = originalFile.name;
    setNewRevisionFiles(prevState => {
      return [...prevState, newFile];
    });
  }, []);

  const handleFileReject = useCallback(error => {
    setErrorMessage(error[0].message);
  }, []);

  const checkFileHasSameEntities = useCallback(
    deletedFile => {
      const totalRelevantEntities = Object.values(
        questionRelevantEntitiesById
      )?.length;
      if (totalRelevantEntities === 1) {
        return formData?.length >= 1;
      }
      const selectedEntities = new Set();
      formData.forEach(fd =>
        fd.entities.forEach(e => {
          selectedEntities.add(e.value);
        })
      );
      const result = deletedFile.entities.some(e =>
        selectedEntities.has(e.value.toString())
      );
      return result;
    },
    [formData, questionRelevantEntitiesById]
  );

  const getDeletedEntities = useCallback(
    file => {
      return localDeletedAnswers.find(a => a.value.id === file.id);
    },
    [localDeletedAnswers]
  );

  const handleRestore = useCallback(
    file => e => {
      e.stopPropagation();
      const deletedFile = structuredClone(getDeletedEntities(file));
      if (responseType === smartFormResponseType.WEBSHEET) {
        if (!deletedFile.entities) {
          setErrorMessage(
            t("common:ui.smartForm.modal.errorMessage.restore.invalidEntities")
          );
          return;
        }
        const hasSameEntities = checkFileHasSameEntities(deletedFile);
        if (hasSameEntities) {
          setErrorMessage(
            t(
              "common:ui.smartForm.modal.errorMessage.restore.duplicateEntities"
            )
          );
          return;
        }
      }
      deletedFile.value.isRestored = true;
      deletedFile.entities = deletedFile.entities?.map(e => ({
        name: e.name,
        value: e.value.toString()
      }));
      setFormData(prev => [...prev, deletedFile]);
      setLocalDeletedAnswers(preDeleteAnswers =>
        preDeleteAnswers.filter(ans => ans?.value.id !== deletedFile?.value.id)
      );
      setShowDeletedDocuments(false);
    },
    [
      checkFileHasSameEntities,
      getDeletedEntities,
      setShowDeletedDocuments,
      responseType,
      t
    ]
  );

  useEffect(() => {
    if (showDeletedDocuments) {
      setUnsavedNewFiles(formData.filter(fd => fd.value.isNew));
    }
  }, [showDeletedDocuments, formData]);

  const renderAction = useCallback(
    file => {
      if (disableAnswerSubmissions) {
        return <></>;
      }
      if (showDeletedDocuments) {
        return (
          !file?.locked && (
            <Button
              variant={ButtonVariant.TEXT_PRIMARY}
              onClick={handleRestore(file)}
              label={t("documents.documentActions_restoreDocument")}
              size={ButtonSize.SMALL}
            />
          )
        );
      }
      const fileExtension = file.name.split(".").pop();
      return (
        <UploadFileButton
          label={t(
            "requests:requests.ui.smartForm.modal.document.replace.label"
          )}
          supportedMimes={[fileExtension]}
          onFilesSelected={handleReplaceFile}
          onFilesRejected={handleFileReject}
          openFileSelection={false}
          maxFiles={1}
          originalFile={file}
        />
      );
    },
    [
      disableAnswerSubmissions,
      showDeletedDocuments,
      t,
      handleReplaceFile,
      handleFileReject,
      handleRestore
    ]
  );

  const renderDocumentCell = useCallback(
    ({ cell }) => {
      if (
        cell.value.responseType == "websheet" ||
        (isExcel(cell.value.name) && !cell.value.isNew)
      ) {
        return (
          <Attachment
            type={cell.value.responseType}
            file={cell.row.original.value}
            onClick={
              cell.row.original?.locked
                ? () => {}
                : openSmartFormWebSheet({
                    projectId,
                    queryId,
                    id: cell.row.original.value.id
                  })
            }
            action={showDeletedDocuments && renderAction}
          />
        );
      }
      return (
        <div className="smart-form-modal__table__content__response-cell">
          <FileAttachmentList
            attachments={[cell.row.original.value]}
            onFileDownloadClicked={handleFileDownload}
            action={renderAction}
            showIconOnlyOnHover={true}
            checkStatus={false}
          />
        </div>
      );
    },
    [handleFileDownload, projectId, queryId, renderAction, showDeletedDocuments]
  );

  const renderBooleanCell = useCallback(
    ({ cell }) => {
      return (
        <div className="smart-form-modal__table__content__response-cell">
          {t("requests:requests.ui.smartForm.responseType.boolean.label", {
            context: cell.row.original.value?.toString()
          })}
        </div>
      );
    },
    [t]
  );

  const renderTextCell = useCallback(({ cell }) => {
    return (
      <div className="smart-form-modal__table__content__response-cell">
        <span className="smart-form-modal__table__content__response-cell__text">
          {cell.row.original.value ?? ""}
        </span>
      </div>
    );
  }, []);

  const renderDateCell = useCallback(
    ({ cell }) => {
      return (
        <div className="smart-form-modal__table__content__response-cell">
          <span className="smart-form-modal__table__content__response-cell__date">
            {dateFormatter(cell.row.original.value, locale, numericFormat) ??
              ""}
          </span>
        </div>
      );
    },
    [locale, numericFormat]
  );

  const renderSingleSelectCell = useCallback(
    ({ cell }) => {
      return (
        <div className="smart-form-modal__table__content__response-cell">
          <span className="smart-form-modal__table__content__response-cell__single-select">
            {smartFormHelper.getSingleSelectValueName(
              cell.row.original.value,
              question.options
            )}
          </span>
        </div>
      );
    },
    [question.options]
  );

  const renderColumn = useMemo(() => {
    const responseType = question.responseType;
    switch (responseType) {
      case smartFormResponseType.DOCUMENT:
      case smartFormResponseType.WEBSHEET:
        return { Cell: renderDocumentCell };
      case smartFormResponseType.BOOLEAN:
        return { Cell: renderBooleanCell };
      case smartFormResponseType.NUMBER:
      case smartFormResponseType.TEXT:
        return { Cell: renderTextCell };
      case smartFormResponseType.DATE:
        return { Cell: renderDateCell };
      case smartFormResponseType.SINGLE_CHOICE:
        return { Cell: renderSingleSelectCell };
    }
  }, [
    question.responseType,
    renderDocumentCell,
    renderBooleanCell,
    renderTextCell,
    renderDateCell,
    renderSingleSelectCell
  ]);

  const checkAnswerStatus = useCallback(
    documentId => {
      const ans = answer?.find(a => a.value?.id == documentId);
      const isCleaned =
        ans?.value?.documentRevisions?.[0]?.properties?.isCleaned;
      const isRestored = ans?.value?.isRestored;
      const result = {
        isCompleted: !!ans?.complete,
        isCleaned: !!isCleaned,
        isRestored: !!isRestored
      };
      return result;
    },
    [answer]
  );

  const getWebsheetMark = useCallback(
    documentId => {
      const status = checkAnswerStatus(documentId);
      if (status.isCleaned && status.isCompleted) {
        return {
          mark: "cleaned",
          text: t("requests:requests.ui.smartForm.websheet.cleanedAndComplete")
        };
      }
      if (status.isCompleted) {
        return {
          mark: "complete",
          text: t("requests:requests.ui.smartForm.websheet.completed")
        };
      }
    },
    [checkAnswerStatus, t]
  );

  const renderWebSheetMark = useCallback(
    documentId => (
      <WebSheetStatusIcon
        color={`success`}
        status={getWebsheetMark(documentId)}
      />
    ),
    [getWebsheetMark]
  );

  const webSheetMark = useCallback(
    questionId => {
      if (responseType === smartFormResponseType.WEBSHEET && answer) {
        return renderWebSheetMark(questionId);
      }
      return null;
    },
    [answer, renderWebSheetMark, responseType]
  );

  useEffect(() => {
    // insert existing answer files to form data and upload documents
    //should only run once
    if (!didMount.current && answer.length) {
      const answerData = getAnswerData(
        answer?.sort((a, b) => Number(a.locked) - Number(b.locked))
      );
      setExistingFiles(
        structuredClone(
          answer?.map(a => {
            return {
              ...a.value,
              isRestored: a.value.isRestored,
              isRevision: false,
              isDeleted: a.value.isDeleted,
              updatedBy: a.updatedBy,
              locked: a.locked || false
            };
          })
        )
      );
      setFormData(answerData);
      didMount.current = true;
    }
    didMount.current = true;
  }, [answer, formattedEntities, getAnswerData, isUnsaved, didMount, question]);

  // used for multiselect in table
  const handleEntitiesChange = useCallback(
    (entities, rowId) => {
      setEntityErrorMessage("");
      setDirtyEntities(true);
      setFormData(prev => {
        return prev.map((p, i) => {
          if (i === rowId) {
            const response = { ...p, entities };
            return { ...response, unsaved: isUnsaved(response) };
          }
          return { ...p };
        });
      });
    },
    [isUnsaved]
  );

  const getModalTableHeader = useMemo(
    () =>
      t("requests:requests.ui.smartForm.modal.table.column.label", {
        context: question.responseType
      }),
    [t, question.responseType]
  );

  const memoData = useMemo(() => {
    return !showDeletedDocuments ? formData : localDeletedAnswers;
  }, [formData, localDeletedAnswers, showDeletedDocuments]);

  const renderContentTable = useMemo(() => {
    return (
      <SmartFormModalTable
        header={getModalTableHeader}
        data={memoData}
        onClickRemove={onClickRemove}
        queryId={queryId}
        responseColumn={renderColumn}
        webSheetMark={
          responseType === smartFormResponseType.WEBSHEET ? webSheetMark : null
        }
        handleEntitiesChange={handleEntitiesChange}
        entities={formattedEntities}
        allowSameEntities={responseType !== smartFormResponseType.WEBSHEET}
        showEntities={formattedEntities?.length}
        checkAnswerStatus={checkAnswerStatus}
        disableAnswerSubmissions={disableAnswerSubmissions}
        showDeletedDocuments={showDeletedDocuments}
        removeDeleted={removeDeleted}
        uploadState={uploadState}
        uploadedFiles={uploadedFiles}
      />
    );
  }, [
    getModalTableHeader,
    memoData,
    onClickRemove,
    queryId,
    renderColumn,
    responseType,
    webSheetMark,
    handleEntitiesChange,
    formattedEntities,
    checkAnswerStatus,
    disableAnswerSubmissions,
    showDeletedDocuments,
    removeDeleted,
    uploadState,
    uploadedFiles
  ]);

  const renderBody = useCallback(() => {
    return {
      content: (
        <div className="smart-form-modal__body">
          {formData?.length || showDeletedDocuments ? (
            <>{renderContentTable}</>
          ) : (
            <div className="smart-form-modal__body__no-content">
              {t(`requests:requests.ui.smartForm.modal.${"noResponses"}.label`)}
            </div>
          )}
        </div>
      ),
      errorMessage
    };
  }, [
    formData?.length,
    showDeletedDocuments,
    renderContentTable,
    t,
    errorMessage
  ]);

  const renderFootnote = useMemo(() => {
    const assignedEntitiesIds = new Set();
    formData?.forEach(r => {
      r.entities?.forEach(entity => {
        assignedEntitiesIds.add(entity.value);
      });
    });

    const answeredEntitiesIds = answer?.reduce((acc, obj) => {
      if (obj.complete) {
        obj?.entities?.forEach(e => acc.add(e));
      }
      return acc;
    }, new Set());

    const answeredEntities = [...(answeredEntitiesIds || [])];
    const assignedEntities = [...assignedEntitiesIds];

    const totalRelevantEntities = Object.values(
      questionRelevantEntitiesById
    )?.length;
    if (totalRelevantEntities > 1) {
      return (
        <SmartFormEntityProgress
          className="smart-form-modal__footnote"
          answeredEntities={answeredEntities}
          assignedEntities={assignedEntities}
          questionRelevantEntities={question.relevantEntities}
          relevantEntitiesById={relevantEntitiesById}
          responseType={question.responseType}
        />
      );
    }
    return <></>;
  }, [
    formData,
    question.relevantEntities,
    questionRelevantEntitiesById,
    relevantEntitiesById,
    answer,
    question.responseType
  ]);

  //Two possible response-false and true. Merge incoming true/false entities with existing true/false entities without duplicates
  const mergeEntities = (current, incoming) => {
    const uniqueEntities = {};
    const prevEntities = {
      false: current.find(x => !x.value)?.entities,
      true: current.find(x => x.value)?.entities
    };

    const allEntities = [
      ...(prevEntities[incoming.value] ?? []),
      ...incoming.entities
    ];
    allEntities.forEach(e => {
      uniqueEntities[e.value] = e.name;
    });
    const newEntities = Object.entries(uniqueEntities).map(([value, name]) => ({
      name,
      value
    }));
    const otherEntities = prevEntities[!incoming.value];
    if (otherEntities) {
      return [
        { value: !incoming.value, entities: otherEntities },
        { value: incoming.value, entities: newEntities }
      ];
    }
    return [{ value: incoming.value, entities: newEntities }];
  };

  const addToFormData = useCallback(
    data => {
      setFormData(prev => {
        const newData = [];
        if (
          [
            smartFormResponseType.NUMBER,
            smartFormResponseType.TEXT,
            smartFormResponseType.DATE,
            smartFormResponseType.SINGLE_CHOICE
          ].includes(responseType)
        ) {
          newData.push(...(prev ?? []), {
            ...data,
            unsaved: true
          });
        } else if (responseType === smartFormResponseType.BOOLEAN) {
          newData.push(...mergeEntities(prev, { ...data, unsaved: true }));
        }
        if (!checkErrors(newData)) {
          return newData;
        }
        return prev;
      });
    },
    [checkErrors, responseType]
  );

  const entitiesWithNoAnswer = useMemo(
    () => smartFormHelper.getEntitiesWithNoAnswer(formattedEntities, formData),
    [formData, formattedEntities]
  );

  const footerContent = useCallback(
    responseType => {
      switch (responseType) {
        case smartFormResponseType.DOCUMENT:
          return (
            <div className="smart-form-modal__upload">
              <Form.UploadDocuments
                key={"SMARTFORM"}
                name={"SMARTFORM"}
                internalOnly={!!queryConfig?.internalOnly}
                errorMessage={entityErrorMessage}
                documentType={""}
                supportedDocumentMimes={supportedDocumentMimes}
                supportedDocumentMimesMessage={""}
                projectId={+projectId}
                onUploadsComplete={handleUploadComplete}
                onChange={onDropFiles}
                disableAttachmentList={true}
                removedFile={removedFile}
                state={uploadState}
                existingFiles={existingFiles}
                revisionFiles={newRevisionFiles}
                disableUploads={disableAnswerSubmissions}
              />
            </div>
          );
        case smartFormResponseType.WEBSHEET:
          return (
            <>
              <div
                className={`smart-form-modal__upload smart-form-modal__upload_${responseType}`}
              >
                <div
                  className={`smart-form-modal__upload_${responseType}_template`}
                >
                  <div
                    className={`smart-form-modal__upload_${responseType}_template_left`}
                  >
                    <Button
                      label={t(
                        "requests:requests.ui.smartForm.modal.fillWebsheet.createWebsheet"
                      )}
                      onClick={onCreateWebSheet}
                      disabled={isCreateWebSheetDisabled}
                    />
                  </div>
                  <div
                    className={`smart-form-modal__upload_${responseType}_template_right`}
                  >
                    {t("requests:requests.ui.smartForm.modal.fillWebsheet.or")}
                  </div>
                </div>
                <div className="smart-form-modal__upload_file">
                  <Form.UploadDocuments
                    key={"SMARTFORM"}
                    name={"SMARTFORM"}
                    internalOnly={!!queryConfig?.internalOnly}
                    errorMessage={""}
                    documentType={""}
                    supportedDocumentMimes={supportedDocumentMimes}
                    supportedDocumentMimesMessage={""}
                    projectId={+projectId}
                    onUploadsComplete={handleUploadComplete}
                    onChange={onDropFiles}
                    disableAttachmentList={true}
                    removedFile={removedFile}
                    state={uploadState}
                    existingFiles={existingFiles}
                    revisionFiles={newRevisionFiles}
                    disableUploads={
                      disableAnswerSubmissions || isCreateWebSheetDisabled
                    }
                  />
                </div>
              </div>
              {entityErrorMessage && (
                <InlineAlert type="error" message={entityErrorMessage} />
              )}
            </>
          );
        case smartFormResponseType.NUMBER:
        case smartFormResponseType.TEXT:
        case smartFormResponseType.BOOLEAN:
        case smartFormResponseType.DATE:
        case smartFormResponseType.SINGLE_CHOICE:
          return (
            <SmartFormEntitiesFooter
              entities={entitiesWithNoAnswer}
              addToFormData={addToFormData}
              responseType={responseType}
              entitiesError={entityErrorMessage}
              options={question.options}
              isLineBreaksAllowed={question.lineBreaks}
              maxLength={question.maxLength}
              inputError={inputErrorMessage}
              setInputError={setInputErrorMessage}
              min={question.min}
              max={question.max}
              // checkErrors={checkErrors(formData)}
            />
          );
        default:
          break;
      }
    },
    [
      queryConfig?.internalOnly,
      entityErrorMessage,
      supportedDocumentMimes,
      projectId,
      handleUploadComplete,
      onDropFiles,
      removedFile,
      uploadState,
      existingFiles,
      newRevisionFiles,
      disableAnswerSubmissions,
      t,
      onCreateWebSheet,
      isCreateWebSheetDisabled,
      entitiesWithNoAnswer,
      addToFormData,
      question.options,
      question.lineBreaks,
      question.maxLength,
      question.min,
      question.max,
      inputErrorMessage
    ]
  );

  const renderFooter = useCallback(() => {
    return {
      content: disableAnswerSubmissions ? <></> : footerContent(responseType),
      submitText: t("requests:requests.ui.smartForm.modal.button.label"),
      handleSubmit,
      handleCancel,
      seperator: !disableAnswerSubmissions,
      footnote: renderFootnote,
      disableSubmit:
        uploadState !== addFilesState.add || disableAnswerSubmissions,
      hideFooter: showDeletedDocuments
    };
  }, [
    disableAnswerSubmissions,
    footerContent,
    responseType,
    t,
    handleSubmit,
    handleCancel,
    renderFootnote,
    uploadState,
    showDeletedDocuments
  ]);

  const modalContentProps = {
    modalClassName: "smart-form-modal",
    header: renderHeader(),
    body: renderBody(),
    footer: renderFooter()
  };

  //separate spinner from the data table to prevent re-render of the whole table component
  //use css to stick the spinner to the uploading file row element
  const renderSpinner = useCallback(() => {
    if (currentUploadingFile?.isUploading) {
      const headerElement = document
        .getElementsByClassName("smart-form-modal__table")[0]
        .getElementsByClassName("data-table__header")[0];
      const bottomElement = document.getElementsByClassName(
        "modal-content-template__content"
      )[0];
      if (!headerElement || !bottomElement) {
        return;
      }
      const headerRect = headerElement.getBoundingClientRect(); // hide spinner when scroll the row element above the table header
      const bottomRect = bottomElement.getBoundingClientRect();

      const topBound = Math.floor(headerRect.top + headerRect.height);
      const botBound = Math.floor(bottomRect.top + bottomRect.height);

      const rowElement = document.getElementsByClassName(
        "smart-form-modal__table__content-action"
      )[currentUploadingFile?.index];
      if (rowElement) {
        const spinnerElement = document.getElementsByClassName(
          "smartform-file-uploading-spinner"
        )[0];
        spinnerElement.style.display = "flex";
        const rect = rowElement?.getBoundingClientRect();
        const { x, y, width, height } = rect;
        const top = Math.floor(y - height / 2 + 5);
        if (top < topBound || top > botBound) {
          spinnerElement.style.display = "none";
        }
        const left = Math.floor(x - width / 2 - 5);
        spinnerElement.style.inset = `${top}px auto auto ${left}px`;
      }
    }
    return (
      <div className="smartform-file-uploading-spinner">
        <ProgressSpinner percentage={currentUploadingFile?.uploadProgress} />
      </div>
    );
  }, [currentUploadingFile]);

  return (
    <>
      {renderSpinner()}
      <ModalForm
        modalContentForm={true}
        modalContentProps={modalContentProps}
      />
    </>
  );
};

SmartFormModal.propTypes = {
  submitText: PropTypes.string,
  queryId: PropTypes.string,
  queryConfig: PropTypes.object,
  question: PropTypes.shape({
    questionId: PropTypes.number.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    responseType: PropTypes.any
  }),
  answer: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      updatedBy: PropTypes.string,
      entities: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  setIsOTUpdate: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  relevantEntitiesById: PropTypes.any,
  disableAnswerSubmissions: PropTypes.bool,
  handleFileDownload: PropTypes.func
};

export default SmartFormModal;
