import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Popup from "@shared/components/popup/Popup";
import { systemConstants } from "@shared/constants";
import { loggedUser } from "@shared/helpers";
import {
  useAuthUser,
  useDeleteProjectMemberMutation,
  useGetProjectMembers,
  useGetProjectMembersToAddQuery,
  useReorderProjectMembers
} from "@shared/hooks";
import usePubSub from "@shared/hooks/usePubSub";

import { sortUsersByOrder } from "@app/helpers";
import { useDefaultLandingPageLink } from "@app/hooks";
import { AccessLevel, ResourceName } from "@app/types";

import { Box, Stack } from "@fermions";

import { Button } from "@atoms/Button";

import ContactList from "@components/molecules/ContactList";
import AddProjectUser from "@components/organisms/AddProjectUser";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate";

import UpdateProjectUser from "../UpdateProjectUser/UpdateProjectUser";
import "./ContactListBox.scss";

/**
 * Component for showing Contact Lists for a project.
 *
 * @param   {object} project             The project to get contacts from
 * @component
 * @example
 * const project = ...;
 * return (
 *   <ContactList project={project} showClientContacts={false} />
 * )
 */

export const contactListActions = {
  ADD_ACTION_ITEMS: "addActionItems",
  HANDLE_REORDER_MEMBERS: "handleReorderMembers"
};

const ContactListBox = props => {
  const {
    project,
    boxClassName,
    handlers,
    registerModal,
    handleOpenModal,
    handleCloseModal
  } = props;

  const { t } = useTranslation();
  const { user: authUser } = useAuthUser();

  const [contacts, setContacts] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const projectId = useMemo(() => project?.id, [project]);

  const { data: usersForProjectAdd, isLoading: isUsersForProjectAddLoading } =
    useGetProjectMembersToAddQuery({
      projectId
    });
  const [removeMember] = useDeleteProjectMemberMutation();
  const { members, refetchMembers, membersLoading } = useGetProjectMembers({
    id: projectId
  });
  const pubSub = usePubSub();
  const didSubscribe = useRef(false);
  const refreshData = useRef();
  const { getDefaultLandingPageLink } = useDefaultLandingPageLink();
  const navigate = useNavigate();

  useEffect(() => {
    if (!didSubscribe.current) {
      pubSub.subscribe(
        systemConstants.project.events.projects.refreshProjectMembers
      );
      didSubscribe.current = true;
    }
  }, [pubSub]);

  useEffect(() => {
    const projectIds = pubSub?.value?.projectIds ?? [];
    if (
      projectIds.length &&
      projectIds.includes(project?.id) &&
      pubSub?.value?.timeStamp !== refreshData.current
    ) {
      refetchMembers();
      refreshData.current = pubSub.value?.timeStamp;
    }
  }, [project, pubSub, refetchMembers]);

  const { reorderProjectMember } = useReorderProjectMembers();

  const getUserEntities = useCallback(
    entities => {
      const entityMap = new Map(
        project.entities?.map(pEntity => [pEntity.externalId, pEntity])
      );
      return entities.map(entityId => entityMap.get(entityId)).filter(Boolean);
    },
    [project?.entities]
  );

  useEffect(() => {
    if (!members) {
      return;
    }
    const hostUsersList = members.hostUsers || [];
    const clientUsersList =
      members.clientUsers.filter(u => !u.isAnonymous) || [];
    const everyone = hostUsersList.concat(clientUsersList);
    const updatedUsers = everyone?.map(user => {
      if (user.entities) {
        return {
          ...user,
          entities: getUserEntities(user.entities)
        };
      }
      return user;
    });

    let newContacts;
    newContacts = structuredClone(updatedUsers);

    const canRemoveMember = candidateUser => {
      if (authUser.isHostUser) {
        return authUser.checkAccess(
          ResourceName.PROJECT_MEMBERS,
          AccessLevel.UPDATE
        );
      }
      if (candidateUser.hostId) {
        return false;
      }
      return authUser.checkAccess(
        ResourceName.PROJECT_MEMBERS,
        AccessLevel.UPDATE
      );
    };

    newContacts.forEach(c => {
      c.canRemoveMember = canRemoveMember(c);
    });
    setContacts?.(sortUsersByOrder(newContacts));
  }, [members, authUser, setContacts, getUserEntities]);

  const addMemberAction = useMemo(() => {
    if (
      authUser.checkAccess(ResourceName.PROJECT_MEMBERS, AccessLevel.CREATE)
    ) {
      return (
        <Box width="100" alignment="right">
          <Button
            variant="primary"
            iconName="person_add"
            label={t("common:ui.projects.members.add")}
            onClick={() => handleOpenModal("addUserToProject")}
            disabled={isUsersForProjectAddLoading}
          />
        </Box>
      );
    }
  }, [authUser, handleOpenModal, isUsersForProjectAddLoading, t]);

  const handleRemoveMember = user => {
    const canReadProjectWithoutMembership = authUser.checkAccess(
      ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
      AccessLevel.READ
    );
    const removingSelf = authUser.id === user.id;

    if (user.canRemoveMember) {
      const userWillLoseProjectAccess =
        !canReadProjectWithoutMembership && removingSelf;
      removeMember({ projectId, userId: user.id, userWillLoseProjectAccess });

      //optimistic navigation to prevent invalidated queries to run (and fail)
      if (userWillLoseProjectAccess) {
        navigate(getDefaultLandingPageLink(authUser));
      }
    }
  };

  const handleReorderContacts = contact => {
    handlers[contactListActions.HANDLE_REORDER_MEMBERS]?.(() => {
      if (!contact.destination) {
        return;
      }
      const contactsOrder = Array.from(contacts);
      const [reorderedItem] = contactsOrder.splice(contact.source.index, 1);
      contactsOrder.splice(contact.destination.index, 0, reorderedItem);
      const updatedContactsOrder = [...contactsOrder].map((c, index) => ({
        id: c.id,
        projectOrder: index
      }));
      reorderProjectMember(project, updatedContactsOrder);
      setContacts?.(contactsOrder);
    });
  };

  const addProjectUserModal = useCallback(
    () => (
      <Popup visibility={true} handleOutsideClick={false} width="60rem">
        <AddProjectUser
          projectMembers={members}
          users={usersForProjectAdd}
          onClose={handleCloseModal}
          project={project}
        />
      </Popup>
    ),
    [handleCloseModal, members, project, usersForProjectAdd]
  );

  const updateProjectUserModal = useCallback(
    () => (
      <Popup visibility={true} handleOutsideClick={false} width="60rem">
        <UpdateProjectUser
          user={selectedUser}
          onClose={handleCloseModal}
          project={project}
        />
      </Popup>
    ),
    [handleCloseModal, project, selectedUser]
  );

  const getMenuItems = useCallback(
    user => {
      const items = [];
      const entitiesEnabled = project.configuration?.entities?.enabled ?? false;

      if (user.canRemoveMember) {
        items.push({
          key: systemConstants.project.members.actions.remove,
          label: t("common:ui.projects.entities.removeFromProject.label")
        });
        if (
          entitiesEnabled &&
          loggedUser.doesBelongToClient(user) &&
          user.isEntityRestricted
        ) {
          items.push({
            key: systemConstants.project.members.actions.editEntities,
            label: t("common:ui.projects.entities.editEntities.label")
          });
        }
      }
      return items;
    },
    [project.configuration?.entities?.enabled, t]
  );

  useEffect(() => {
    registerModal?.("addUserToProject", addProjectUserModal);
    registerModal?.("updateProjectUser", updateProjectUserModal);
  }, [addProjectUserModal, registerModal, updateProjectUserModal]);

  const context = project?.configuration?.i18nContextKey;

  const handleUserActionClick = data => {
    if (data.action === systemConstants.project.members.actions.remove) {
      handleRemoveMember(data.user);
    } else if (
      data.action === systemConstants.project.members.actions.editEntities
    ) {
      setSelectedUser(data.user);
      handleOpenModal("updateProjectUser");
    }
  };

  return (
    <DashboardBoxTemplate
      boxClassName={boxClassName}
      title={t("common:ui.projects.members.title", { context })}
      subtext={
        authUser.isHostUser
          ? t("common:ui.projects.members.subtitle", { context })
          : ""
      }
    >
      <Stack height="100">
        <Box height="100">
          <ContactList
            contacts={contacts}
            enableDrag={authUser.isHostUser}
            isContactsLoading={membersLoading}
            handleUserActionClick={handleUserActionClick}
            handleReorderContacts={handleReorderContacts}
            onClick={handlers[contactListActions.ADD_ACTION_ITEMS]}
            getMenuItems={getMenuItems}
            hideCreateRequest={!authUser || !!addMemberAction}
            isEngagementTypeSupportEntities={
              project.configuration?.entities?.enabled
            }
          />
        </Box>
        {addMemberAction}
      </Stack>
    </DashboardBoxTemplate>
  );
};

ContactListBox.propTypes = {
  project: PropTypes.object.isRequired,
  boxClassName: PropTypes.string.isRequired,
  handlers: PropTypes.shape({
    addActionItems: PropTypes.func,
    handleReorderMembers: PropTypes.func
  }).isRequired,
  registerModal: PropTypes.func,
  handleOpenModal: PropTypes.func,
  handleCloseModal: PropTypes.func
};

export default ContactListBox;
