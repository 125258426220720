import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import Popup from "@shared/components/popup/Popup";
import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import {
  actionItemServiceUtil,
  projectMemberUtil,
  projectServiceUtil,
  useAuthUser,
  useCurrentProject,
  useFeatures,
  useGetProjectByIdQuery,
  useGetProjectMembers,
  useLazyGetMenusQuery
} from "@shared/hooks";
import { useModalContent } from "@shared/hooks/useModalContent";
import usePubSub from "@shared/hooks/usePubSub";

import { routeConstants } from "@app/constants/routeConstants";
import { AccessLevel, ResourceName } from "@app/types";

import { Box } from "@fermions";

import { Button } from "@atoms/Button";
import { Placeholder, PlaceholderContext } from "@atoms/Placeholder";

import Loading from "@molecules/Loading";

import List from "@components/atoms/List/List";
import EntityList from "@components/molecules/EntityList";
import ReminderCircle from "@components/molecules/ReminderCircle";
import ActionItemsBoard from "@components/organisms/ActionItemsBoard";
import AddOrCopyProjectActionItems from "@components/organisms/AddOrCopyProjectActionItems";
import ContactListBox from "@components/organisms/ContactListBox";
import { contactListActions } from "@components/organisms/ContactListBox/ContactListBox";
import MilestoneGraph from "@components/organisms/MilestoneGraph";
import Milestones from "@components/organisms/Milestones/Milestones";
import News from "@components/organisms/News";
import UploadFileBox from "@components/organisms/UploadFileBox";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate/DashboardBoxTemplate";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./ProjectSummary.scss";

const ProjectSummary = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const {
    currentProject,
    onChangeCurrentProject,
    onUpdateCurrentProject,
    resetCurrentProject
  } = useCurrentProject();

  const { state: projectFromLocationState } = useLocation();
  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || currentProject?.id === projectId }
    );

  const project = useMemo(() => {
    // Current project isn't loaded to match params yet
    if (currentProject?.id != projectId) {
      return undefined;
    }
    return utilities.getProjectWithMilestoneData(currentProject);
  }, [currentProject, projectId]);

  const pubSub = usePubSub();
  const didSubscribe = useRef(false);
  const refreshData = useRef();

  useEffect(() => {
    if (!didSubscribe.current) {
      pubSub.subscribe(
        systemConstants.project.events.projects.refreshProjectMembers
      );
      didSubscribe.current = true;
    }
  }, [pubSub]);

  useEffect(() => {
    const projectIds = pubSub?.value?.projectIds ?? [];
    if (
      pubSub?.value &&
      pubSub.value.message === "access-updated" &&
      projectIds.includes(currentProject?.id) &&
      pubSub.value.timeStamp !== refreshData.current
    ) {
      dispatch(
        projectServiceUtil.invalidateTags([
          { type: "Project", id: currentProject.id }
        ])
      );
      dispatch(actionItemServiceUtil.invalidateTags(["Query"]));
      dispatch(projectMemberUtil.invalidateTags(["ProjectMembers"]));
      resetCurrentProject();
      refreshData.current = pubSub.value?.timeStamp;
    }
  }, [pubSub, dispatch, currentProject, resetCurrentProject]);

  const { members, isProjectMember } = useGetProjectMembers({ id: projectId });
  const {
    modalOpen,
    getModalContent,
    registerModal,
    handleOpenModal,
    handleCloseModal,
    projectAccessModal
  } = useModalContent();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuthUser({ withProfile: false });

  const [getInteractiveReportMenus, interactiveReportMenusData] =
    useLazyGetMenusQuery();
  const { isEnabled, isInteractiveReportEnabled } = useFeatures();

  // Handle Error in loading project
  useEffect(() => {
    if (isInvalidProject) {
      navigate("/not-found", { replace: true });
    }
  }, [isInvalidProject, navigate]);

  useEffect(() => {
    // handles case where we access 'project-summary' with project information in the location.state (e.g click link, project selector)
    // or if the user has done a project swap (e.g. via URL)
    if (projectId) {
      return;
    }
    if (projectFromLocationState?.project?.id) {
      navigate(`/projects/${projectFromLocationState.project.id}`, {
        replace: true
      });
    } else if (currentProject) {
      navigate(`/projects/${currentProject.id}`, { replace: true });
    }
  }, [projectId, currentProject, navigate, projectFromLocationState]);

  useEffect(() => {
    if (!projectToSelect) {
      return;
    }
    if (currentProject?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    } else {
      onUpdateCurrentProject(projectToSelect);
    }
  }, [
    projectToSelect,
    currentProject,
    onChangeCurrentProject,
    onUpdateCurrentProject
  ]);

  useEffect(() => {
    if (projectId && isInteractiveReportEnabled) {
      getInteractiveReportMenus({ projectId });
    }
  }, [getInteractiveReportMenus, isInteractiveReportEnabled, projectId]);

  const getReminderDays = useCallback(p => {
    if (!p) {
      return "";
    }
    if (p.daysForNextMilestone || p.daysForNextMilestone === 0) {
      return `${Math.abs(p.daysForNextMilestone)}`;
    } else {
      return "NO";
    }
  }, []);

  const getMilestoneMessage = useCallback(
    p => {
      if (!p) {
        return {};
      }
      return {
        top: t("common:ui.projectSummary.milestoneMessage.top", {
          count: Math.abs(p.daysForNextMilestone),
          context: p.milestoneStatus?.replace(" ", "_")
        }),
        bottom: t("common:ui.projectSummary.milestoneMessage.bottom", {
          context: p.milestoneStatus?.replace(" ", "_")
        }),
        complete: t("common:ui.projectSummary.milestoneMessage.complete")
      };
    },
    [t]
  );

  const projectError = useCallback(
    msg => {
      handleOpenModal("projectAccess", `${msg}`);
    },
    [handleOpenModal]
  );

  const handleAddActionItems = useCallback(() => {
    isProjectMember(
      user,
      t("requests:requests.ui.projectSummary.accessRequiredActionSendRequest"),
      () => {
        handleOpenModal("actionForm");
      },
      projectError
    );
  }, [handleOpenModal, isProjectMember, projectError, t, user]);

  const handleClickUpdateMilestone = useCallback(() => {
    isProjectMember(
      user,
      t("common:ui.projectSummary.accessRequiredUpdateMilestone"),
      () => {
        handleOpenModal("updateMilestones");
      },
      projectError
    );
  }, [handleOpenModal, isProjectMember, projectError, t, user]);

  const checkMembership = useCallback(
    msg => cb => {
      isProjectMember(user, msg, cb, projectError);
    },
    [isProjectMember, projectError, user]
  );

  const renderMilestoneBox = useCallback(() => {
    return (
      <div className="milestone-box">
        <div className="milestone-box__left">
          {project ? (
            <MilestoneGraph
              milestones={project?.milestones ?? []}
              project={project}
              handleClickUpdateMilestone={handleClickUpdateMilestone}
            />
          ) : (
            <Box width="fit" padding="150">
              <Loading />
            </Box>
          )}
        </div>
        <div className="milestone-box__right">
          <ReminderCircle
            days={project ? getReminderDays(project) : ""}
            message={project ? getMilestoneMessage(project) : ""}
            percentage={project ? (project?.percentage ?? 0) : 0}
            isCompleted={project ? (project?.isCompleted ?? false) : false}
          />
        </div>
      </div>
    );
  }, [
    project,
    getMilestoneMessage,
    getReminderDays,
    handleClickUpdateMilestone
  ]);

  const onAddActionSubmitted = useCallback(() => {
    handleCloseModal();
    navigate(routeConstants.request.requests, { state: { project } });
  }, [handleCloseModal, navigate, project]);

  const getActions = () => {
    if (!project) {
      return <></>;
    }

    return <>{getCopyProjectButton()}</>;
  };

  const getCopyProjectButton = () => {
    if (!project?.isCompleted || !user.isHostUser) {
      return <></>;
    }

    const canCreateProjects =
      user.checkAccess(
        ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
        AccessLevel.CREATE
      ) ||
      (user.checkAccess(
        ResourceName.PROJECTS_WITH_MEMBERSHIP,
        AccessLevel.CREATE
      ) &&
        project.isMember);
    if (!canCreateProjects) {
      return <></>;
    }

    return (
      <NavLink
        to={`/projects/${project.id}/copy`}
        state={{ engagement: project.engagement }}
      >
        <Button label={t("common:ui.project.title_copy")} />
      </NavLink>
    );
  };

  const displayUsefulLinks = useCallback(() => {
    const usefulLinks =
      interactiveReportMenusData?.data?.reduce(
        (prev, curr) => [
          ...prev,
          ...curr.pages.filter(({ usefulLink }) => usefulLink)
        ],
        []
      ) ?? [];

    if (usefulLinks?.length === 0) {
      return <div>{t("common:ui.projects.usefulLinks.none")}</div>;
    }

    return (
      <List
        list={usefulLinks?.map(data => {
          return {
            id: data.id,
            name: data.pageName,
            linkTo: `pages/${data?.id}`
          };
        })}
      />
    );
  }, [interactiveReportMenusData?.data, t]);

  const displayMidSummaryBody = useCallback(() => {
    if (!project) {
      return <Placeholder content={""} context={PlaceholderContext.SURFACE} />;
    }

    const configuration = currentProject?.configuration ?? {};

    const isNewsEnabled =
      configuration.news?.enabled &&
      isEnabled(systemConstants.features.manageNews);

    const both = isNewsEnabled && configuration.interactiveReport?.quickLinks;
    const none = !isNewsEnabled && !configuration.interactiveReport?.quickLinks;
    const className = both ? "two" : "one";
    if (none) {
      return (
        <Placeholder
          content={<h2>{t("common:ui.projects.features.none")}</h2>}
          context={PlaceholderContext.SURFACE}
        />
      );
    }

    return (
      <>
        {isNewsEnabled && (
          <div className={className}>
            <News
              engagementTypeId={project?.engagement?.engagementTypeId}
              newsConfiguration={project?.configuration?.news}
              engagementTypeContextKey={project?.configuration?.i18nContextKey}
            />
          </div>
        )}
        {isInteractiveReportEnabled &&
          configuration.interactiveReport?.quickLinks && (
            <div className={className}>
              <DashboardBoxTemplate
                className={className}
                title={t("common:ui.projects.usefulLinks.label")}
                boxClassName={"useful-links-box"}
              >
                {displayUsefulLinks()}
              </DashboardBoxTemplate>
            </div>
          )}
      </>
    );
  }, [
    currentProject?.configuration,
    isEnabled,
    project,
    isInteractiveReportEnabled,
    t,
    displayUsefulLinks
  ]);

  const bodyPrimary = useMemo(() => {
    return (
      <>
        <div className="project-summary__body__box">
          <ActionItemsBoard project={project} projectMembers={members} />
          {project ? (
            project?.configuration?.entities?.enabled ? (
              <EntityList
                entities={project?.entities || []}
                project={project}
              />
            ) : (
              <UploadFileBox
                title={t("common:ui.documents.fileUpload.label")}
                project={project}
                boxClassName={"upload-file-box"}
                checkIsMember={true}
                registerModal={registerModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            )
          ) : (
            <Placeholder content={""} context={PlaceholderContext.SURFACE} />
          )}
        </div>
        <div className="project-summary__body__box">
          {displayMidSummaryBody()}
        </div>
        <div className="project-summary__body__box">
          {project && user ? (
            <ContactListBox
              project={project}
              boxClassName={"contact-list-box"}
              handlers={{
                [contactListActions.ADD_ACTION_ITEMS]: handleAddActionItems,
                [contactListActions.HANDLE_REORDER_MEMBERS]: checkMembership(
                  t("common:ui.projectSummary.accessRequiredReorderMembers")
                )
              }}
              registerModal={registerModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
            />
          ) : (
            <Placeholder content={""} context={PlaceholderContext.SURFACE} />
          )}
        </div>
      </>
    );
  }, [
    project,
    members,
    t,
    registerModal,
    handleOpenModal,
    handleCloseModal,
    displayMidSummaryBody,
    user,
    handleAddActionItems,
    checkMembership
  ]);

  const actionFormModal = useMemo(
    () => () => (
      <AddOrCopyProjectActionItems
        project={project}
        onCancel={handleCloseModal}
        onSubmitted={onAddActionSubmitted}
      />
    ),
    [handleCloseModal, onAddActionSubmitted, project]
  );

  const updateMilestonesModal = useMemo(
    () => () => (
      <Popup visibility={true} handleOutsideClick={false} width="60rem">
        <Milestones
          milestones={project ? (project?.milestones ?? []) : []}
          project={project}
          onSave={handleCloseModal}
          onCancel={handleCloseModal}
        />
      </Popup>
    ),
    [handleCloseModal, project]
  );

  useEffect(() => {
    if (project && user) {
      registerModal("projectAccess", projectAccessModal);
      registerModal("actionForm", actionFormModal);
      registerModal("updateMilestones", updateMilestonesModal);
    }
  }, [
    actionFormModal,
    project,
    projectAccessModal,
    registerModal,
    updateMilestonesModal,
    user
  ]);

  const pageTitle = useMemo(() => {
    const currentProjectName = project ? `${project?.name} ` : "";
    return `${currentProjectName}${t("common:ui.dashboard.navigation.title")}`;
  }, [project, t]);

  return (
    <PageTemplate
      header={{
        title: pageTitle,
        actions: getActions(),
        content: renderMilestoneBox()
      }}
      other={{
        project: currentProject
      }}
      body={{
        primary: bodyPrimary
      }}
      modal={{
        open: modalOpen,
        content: getModalContent()
      }}
      classNames={{
        page: "project-summary"
      }}
    />
  );
};

export default ProjectSummary;
