import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import { projectDocumentUtil } from "./projectDocumentService";
import prepareHeaders from "./rtkUtilities";

const tagStatus = {
  active: "ACTIVE",
  deleted: "DELETED"
};

export const projectTagService = createApi({
  reducerPath: "projectTagApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["projectTags"],
  endpoints: builder => ({
    getProjectTags: builder.query({
      query: ({ projectId }) => `/api/projects/${projectId}/tags`,
      providesTags: ["projectTags"]
    }),
    createProjectTag: builder.mutation({
      query: ({ tag, projectId }) => ({
        url: `/api/projects/${projectId}/tags`,
        method: "POST",
        body: tag
      }),
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const { tag, projectId } = request;
        try {
          const result = await queryFulfilled;
          if (result.data) {
            dispatch(
              projectTagService.util.updateQueryData(
                "getProjectTags",
                { projectId },
                draft => {
                  draft.push({
                    ...tag,
                    id: result.data.id,
                    status: tagStatus.active
                  });
                }
              )
            );
          }
        } catch (e) {}
      }
    }),
    copyProjectTags: builder.mutation({
      query: ({ tags, projectId }) => ({
        url: `/api/projects/${projectId}/tags`,
        method: "POST",
        body: tags
      }),
      invalidatesTags: ["projectTags"]
    }),
    updateProjectTag: builder.mutation({
      query: ({ tag, projectId }) => ({
        url: `/api/projects/${projectId}/tags/${tag.id}`,
        method: "PUT",
        body: tag
      }),
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const { tag, projectId } = request;
        const patchResult = dispatch(
          projectTagService.util.updateQueryData(
            "getProjectTags",
            { projectId },
            draft => {
              const index = draft.findIndex(item => item.id === tag.id);
              if (index !== -1) {
                draft[index] = tag;
              }
            }
          )
        );
        try {
          await queryFulfilled;
          dispatch(
            projectDocumentUtil.invalidateTags([
              { type: "projectDocuments", id: "LIST" }
            ])
          );
        } catch (e) {
          patchResult.undo();
        }
      }
    }),
    deleteProjectTag: builder.mutation({
      query: ({ tagId, projectId, force }) => ({
        url: `/api/projects/${projectId}/tags/${tagId}${force ? "/force" : ""}`,
        method: "DELETE"
      }),
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const { tagId, projectId } = request;
        const patchResult = dispatch(
          projectTagService.util.updateQueryData(
            "getProjectTags",
            { projectId },
            draft => {
              const index = draft.findIndex(item => item.id === tagId);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            }
          )
        );
        try {
          await queryFulfilled;
          dispatch(
            projectDocumentUtil.invalidateTags([
              { type: "projectDocuments", id: "LIST" }
            ])
          );
        } catch (e) {
          patchResult.undo();
        }
      }
    })
  })
});

export const {
  useGetProjectTagsQuery,
  useCreateProjectTagMutation,
  useCopyProjectTagsMutation,
  useUpdateProjectTagMutation,
  useDeleteProjectTagMutation,
  util: projectTagUtil
} = projectTagService;
