import { TFunction } from "i18next";

import { ActionItemType } from "@app/types";

function formatActionItemType(actionItemType: ActionItemType, t: TFunction) {
  const label = actionItemType.configuration.useCustomName
    ? actionItemType.name
    : t("requests:requests.configured.name", {
        context: actionItemType.configuration.key
      });
  return {
    name: actionItemType.name,
    label,
    value: actionItemType.configuration.key
  };
}

export function formatActionItemTypes({
  actionItemTypes,
  isUserEntityRestricted,
  t
}: {
  actionItemTypes: ActionItemType[];
  isUserEntityRestricted: boolean;
  t: TFunction;
}) {
  if (isUserEntityRestricted) {
    const actionItemTypesWithEntities = actionItemTypes.filter(ait =>
      ait.configuration.fields.some(field => field.key === "entities")
    );
    return actionItemTypesWithEntities.map(ait => formatActionItemType(ait, t));
  }
  return actionItemTypes.map(ait => formatActionItemType(ait, t));
}
