import { combineReducers } from "redux";

import { systemConstants } from "@shared/constants/systemConstants";
import actionItemReducer from "@shared/features/actionItem/actionItemSlice";
import {
  interactiveReportJsonEditorSliceReducer,
  interactiveReportSliceReducer
} from "@shared/features/interactiveReport/interactiveReportSlice";
import secondaryNavReducer from "@shared/features/secondaryNav/secondaryNavSlice";
import { actionItemService } from "@shared/services/actionItemService";
import { actionItemTypesService } from "@shared/services/actionItemTypesService";
import { aiCleaningAssistantService } from "@shared/services/aiCleaningAssistantService";
import { auditService } from "@shared/services/auditService";
import { cleaningTemplateService } from "@shared/services/cleaningTemplateService";
import { clientProjectsService } from "@shared/services/clientProjectsService";
import { clientService } from "@shared/services/clientService";
import { cmsService } from "@shared/services/cmsService";
import { documentMimesService } from "@shared/services/documentMimesService";
import { documentService } from "@shared/services/documentService";
import { engagementTypeService } from "@shared/services/engagementTypeService";
import { engagementsService } from "@shared/services/engagementsService";
import { excelDocumentService } from "@shared/services/excelDocumentService";
import { featuresService } from "@shared/services/featuresService";
import { filtersService } from "@shared/services/filtersService";
import { footerService } from "@shared/services/footerService";
import { insightsService } from "@shared/services/insightsService";
import { interactiveReportService } from "@shared/services/interactiveReportService";
import { jsonEditorService } from "@shared/services/jsonEditorService";
import { newsService } from "@shared/services/newsService";
import { projectDashboardService } from "@shared/services/projectDashboardService";
import { projectDocumentService } from "@shared/services/projectDocumentService";
import { projectMemberService } from "@shared/services/projectMemberService";
import { projectService } from "@shared/services/projectService";
import { projectTagService } from "@shared/services/projectTagService";
import { projectTemplateService } from "@shared/services/projectTemplateService";
import { queryResponseService } from "@shared/services/queryResponseService";
import { riskService } from "@shared/services/riskService";
import { rolesService } from "@shared/services/roleService";
import { smartformService } from "@shared/services/smartformService";
import { tokenService } from "@shared/services/tokenService";
import { userService } from "@shared/services/userService";

import { alert } from "./alertReducer";
import { authentication } from "./authenticationReducer";
import { clientDocuments } from "./clientDocumentsReducer";
import { externalLinkValidation } from "./externalLinkValidationReducer";
import { manageArticles } from "./manageArticlesReducer";
import { manageClientEngagementProjects } from "./manageClientEngagementProjectsReducer";
import { manageClientEngagements } from "./manageClientEngagementsReducer";
import { manageClientProjects } from "./manageClientProjectsReducer";
import { manageClientTags } from "./manageClientTagsReducer";
import { manageClients } from "./manageClients";
import { manageDashboard } from "./manageDashboardReducer";
import { manageDocumentDownloads } from "./manageDocumentDownloadsReducer";
import { manageDocumentUploads } from "./manageDocumentUploadsReducer";
import { manageExcelDocument } from "./manageExcelDocumentReducer";
import { manageHostUsers } from "./manageHostUsersReducer";
import { manageInteractiveReport } from "./manageInteractiveReportReducer";
import { manageProjectDocuments } from "./manageProjectDocumentsReducer";
import { manageProjectQueries } from "./manageProjectQueriesReducer";
import { manageProject } from "./manageProjectReducer";

const allReducers = combineReducers({
  authentication,
  alert,
  clientDocuments,
  manageClients,
  manageClientTags,
  manageHostUsers,
  manageClientEngagements,
  manageClientEngagementProjects,
  manageProject,
  manageProjectDocuments,
  manageDocumentDownloads,
  manageExcelDocument,
  manageDashboard,
  manageProjectQueries,
  manageDocumentUploads,
  externalLinkValidation,
  manageArticles,
  manageClientProjects,
  manageInteractiveReport,
  interactiveReport: interactiveReportSliceReducer,
  interactiveReportJsonEditor: interactiveReportJsonEditorSliceReducer,
  actionItem: actionItemReducer,
  secondaryNavSlice: secondaryNavReducer,
  [interactiveReportService.reducerPath]: interactiveReportService.reducer,
  [footerService.reducerPath]: footerService.reducer,
  [actionItemService.reducerPath]: actionItemService.reducer,
  [actionItemTypesService.reducerPath]: actionItemTypesService.reducer,
  [smartformService.reducerPath]: smartformService.reducer,
  [featuresService.reducerPath]: featuresService.reducer,
  [filtersService.reducerPath]: filtersService.reducer,
  [projectService.reducerPath]: projectService.reducer,
  [projectTemplateService.reducerPath]: projectTemplateService.reducer,
  [documentService.reducerPath]: documentService.reducer,
  [documentMimesService.reducerPath]: documentMimesService.reducer,
  [engagementsService.reducerPath]: engagementsService.reducer,
  [insightsService.reducerPath]: insightsService.reducer,
  [projectDashboardService.reducerPath]: projectDashboardService.reducer,
  [projectDocumentService.reducerPath]: projectDocumentService.reducer,
  [projectMemberService.reducerPath]: projectMemberService.reducer,
  [queryResponseService.reducerPath]: queryResponseService.reducer,
  [engagementTypeService.reducerPath]: engagementTypeService.reducer,
  [cleaningTemplateService.reducerPath]: cleaningTemplateService.reducer,
  [clientProjectsService.reducerPath]: clientProjectsService.reducer,
  [clientService.reducerPath]: clientService.reducer,
  [cmsService.reducerPath]: cmsService.reducer,
  [newsService.reducerPath]: newsService.reducer,
  [userService.reducerPath]: userService.reducer,
  [rolesService.reducerPath]: rolesService.reducer,
  [jsonEditorService.reducerPath]: jsonEditorService.reducer,
  [riskService.reducerPath]: riskService.reducer,
  [aiCleaningAssistantService.reducerPath]: aiCleaningAssistantService.reducer,
  [auditService.reducerPath]: auditService.reducer,
  [tokenService.reducerPath]: tokenService.reducer,
  [excelDocumentService.reducerPath]: excelDocumentService.reducer,
  [projectTagService.reducerPath]: projectTagService.reducer
});

function rootReducer(state, action) {
  if (action.type === systemConstants.logout.resetApp) {
    state = { cms: state.cms };
  }
  return allReducers(state, action);
}

export default rootReducer;
