import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useGetProjectMembersQuery } from "@shared/services/projectMemberService";

import { AnonymousUserType } from "@atoms/Avatar/Avatar";

export const useLookupProjectUser = (projectId: number) => {
  interface ProjectUserInfo {
    id: number;
    isAnonymous?: boolean;
    name?: string;
    firstname: string;
    lastname: string;
    loading?: boolean;
  }
  const { t } = useTranslation();
  const { data: projectMembers, isLoading: isLoadingProjectMembers } =
    useGetProjectMembersQuery(projectId, {
      skip: !projectId
    });

  const getProjectUser = useMemo(
    () => (user: { id: number }) => {
      const formattedUser = (member: ProjectUserInfo) => {
        if (member.isAnonymous) {
          return {
            ...member,
            name: t("common:details.redacted"),
            type: "entityRestricted"
          };
        } else if (!member.name) {
          return {
            ...member,
            name: `${member.firstname} ${member.lastname}`
          };
        }
        return member;
      };

      const notAMember = {
        id: user.id,
        isAnonymous: true,
        name: t("common:anonymousUser.name"),
        type: AnonymousUserType.REMOVED_PROJECT_MEMBER
      };

      if (isLoadingProjectMembers) {
        return {
          ...user,
          loading: true
        };
      }
      const allMembers = [
        ...(projectMembers?.clientUsers ?? []),
        ...(projectMembers?.hostUsers ?? [])
      ];
      const member = allMembers?.find(member => member.id === user.id);
      return member ? formattedUser(member) : notAMember;
    },
    [
      isLoadingProjectMembers,
      projectMembers?.clientUsers,
      projectMembers?.hostUsers,
      t
    ]
  );

  return {
    getProjectUser
  };
};
