import React, { useEffect, useRef } from "react";

import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { systemConstants } from "@shared/constants";
import {
  actionItemServiceUtil,
  projectMemberUtil,
  projectServiceUtil,
  useCurrentProject,
  useGetProjectByIdQuery
} from "@shared/hooks";
import usePubSub from "@shared/hooks/usePubSub";

import { routeConstants } from "@app/constants";

import ActionItems from "@components/organisms/ActionItems";

import "./ManageActionItems.scss";

const ManageActionItems = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    currentProject,
    onChangeCurrentProject,
    onUpdateCurrentProject,
    resetCurrentProject
  } = useCurrentProject();
  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || currentProject?.id === projectId }
    );

  const pubSub = usePubSub();
  const didSubscribe = useRef(false);
  const refreshData = useRef();

  useEffect(() => {
    if (!didSubscribe.current) {
      pubSub.subscribe(
        systemConstants.project.events.projects.refreshProjectMembers
      );
      didSubscribe.current = true;
    }
  }, [pubSub]);

  useEffect(() => {
    const projectIds = pubSub?.value?.projectIds ?? [];
    if (
      pubSub?.value &&
      pubSub.value.message === "access-updated" &&
      projectIds.includes(currentProject?.id) &&
      pubSub.value.timeStamp !== refreshData.current
    ) {
      dispatch(
        projectServiceUtil.invalidateTags([
          { type: "Project", id: currentProject.id }
        ])
      );
      dispatch(projectMemberUtil.invalidateTags(["ProjectMembers"]));
      dispatch(actionItemServiceUtil.invalidateTags(["Query"]));
      resetCurrentProject();
      refreshData.current = pubSub.value?.timeStamp;
    }
  }, [pubSub, dispatch, currentProject, resetCurrentProject]);

  useEffect(() => {
    if (!projectToSelect) {
      return;
    }
    if (projectToSelect && currentProject?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    } else {
      onUpdateCurrentProject(projectToSelect);
    }
  }, [
    projectToSelect,
    currentProject,
    onChangeCurrentProject,
    onUpdateCurrentProject
  ]);

  useEffect(() => {
    if (!projectId && currentProject) {
      navigate(`/projects/${currentProject.id}/queries`, { replace: true });
    }
  }, [navigate, currentProject, projectId]);

  useEffect(() => {
    if (isInvalidProject || (!projectId && !currentProject)) {
      navigate(routeConstants.login);
    }
  }, [isInvalidProject, navigate, projectId, currentProject]);

  return (
    <ActionItems project={currentProject} filters={location.state?.filters} />
  );
};

export default ManageActionItems;
