import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import { systemConstants } from "@shared/constants";
import { useAuthUser } from "@shared/hooks";
import {
  useGetClientUsersQuery,
  useGetEntitiesQuery,
  useGetHostUsersQuery
} from "@shared/services";

import { getErrorMessage } from "@app/helpers/error";
import { AccessLevel, ResourceName } from "@app/types";

import { Stack } from "@fermions";

import { InputContainer } from "@templates/InputContainer";

import Form from "@components/atoms/Form";
import SelectProjectYearField from "@components/molecules/SelectProjectYearField";
import UploadProjectEntitiesFormField from "@components/molecules/UploadProjectEntitiesFormField";
import SelectClientDropdown from "@components/organisms/SelectClientDropdown";
import SelectEngagementTypeDropdown from "@components/organisms/SelectEngagementTypeDropdown";
import BoxTemplate from "@components/templates/BoxTemplate";

import { ProjectFormQueries } from "./ProjectFormQueries";
import { MilestoneGenerator } from "./utilities/template.milestones";
import {
  MilestoneCalculatorType,
  MilestoneType
} from "./utilities/template.milestones.types";

function ProjectForm(props) {
  const {
    enableEntities,
    enableExternalEntities,
    enableProjectManager,
    enableProjectYear,
    enableProjectExternalId,
    enableCopyEngagementUsers,
    isTemplateSelected,
    projectTemplate,
    enableProjectQueries,
    startDateLabel,
    endDateLabel,
    projectManagers,
    project,
    showUpdateFieldsOnly,
    handleEngagementTypeChange,
    isError,
    error,
    title,
    subText,
    crudFormMode = systemConstants.project.crudFormMode.create
  } = props;
  const { watch, reset, setValue } = useFormContext();
  const { t } = useTranslation();
  const [projectMilestones, setProjectMilestones] = useState([]);

  const { user } = useAuthUser();
  const watchYear = watch("year");
  const watchStartDate = watch("startDate");
  const watchEndDate = watch("plannedCompletionDate");
  const watchClient = watch("client");
  const watchEngagementType = watch("engagementType");

  const watchYearRef = useRef(watchYear);
  const projectTemplateIdRef = useRef(projectTemplate?.id);
  const clearFieldRef = useRef(false);

  const disabledEndDateMilestoneType = useMemo(
    () => [
      MilestoneCalculatorType.FIXED_DAYS_INTERVAL,
      MilestoneCalculatorType.NTH_OCCURRENCE_DAY
    ],
    []
  );

  const hasReadAccess = useMemo(
    () => resourceName => user.checkAccess(resourceName, AccessLevel.READ),
    [user]
  );

  const { data: hostUsers = [] } = useGetHostUsersQuery(null, {
    skip: !enableProjectQueries || !hasReadAccess(ResourceName.HOST_USERS)
  });
  const { data: clientUsers = [] } = useGetClientUsersQuery(
    watchClient?.value?.id,
    {
      skip:
        !watchClient?.value?.id ||
        !enableProjectQueries ||
        !hasReadAccess(ResourceName.CLIENT_USERS)
    }
  );

  const { error: fetchExternalError, data: externalEntities = [] } =
    useGetEntitiesQuery(watchClient?.value?.id, {
      skip: !watchClient?.value?.id || !enableExternalEntities
    });

  useEffect(() => {
    if (!enableExternalEntities) {
      return;
    }
    if (fetchExternalError) {
      setValue("entities", "");
      return;
    }
    if (externalEntities?.data) {
      const formattedEntities = externalEntities.data
        .map(entity => `${entity.entityName}, ${entity.entityId}`)
        .join("\n");
      setValue("entities", formattedEntities);
    }
  }, [
    externalEntities.data,
    setValue,
    project.entities,
    fetchExternalError,
    enableExternalEntities
  ]);

  useEffect(() => {
    if (
      watchClient?.value?.id &&
      isTemplateSelected &&
      projectTemplate?.queries?.length
    ) {
      setValue("assignedTo", null);
      setValue("copiedTo", null);
    }
  }, [
    isTemplateSelected,
    projectTemplate?.queries?.length,
    setValue,
    watchClient?.value?.id
  ]);

  useEffect(() => {
    if (projectTemplate?.milestones) {
      const milestoneGenerator = new MilestoneGenerator();

      const isProjectYearChanged =
        watchYearRef?.current !== watchYear && watchYearRef?.current !== null;

      const isProjectTemplateChanged =
        projectTemplateIdRef?.current !== projectTemplate?.id &&
        projectTemplateIdRef?.current !== null;

      // Reset milestones date to trigger recalculation
      const projectTemplateMilestones = projectTemplate.milestones.map(
        milestone => {
          return {
            ...milestone,
            date: undefined
          };
        }
      );

      const endMilestone = projectTemplateMilestones.find(
        milestone => milestone.type === MilestoneType.END
      );

      const milestones = milestoneGenerator.getAllMilestones(
        projectTemplateMilestones,
        {
          properties: { year: watchYear?.value },
          startDate:
            isProjectYearChanged || isProjectTemplateChanged
              ? undefined
              : watchStartDate,
          plannedCompletionDate:
            isProjectYearChanged ||
            isProjectTemplateChanged ||
            disabledEndDateMilestoneType.includes(
              endMilestone?.calculator?.type
            )
              ? undefined
              : watchEndDate
        }
      );

      if (isProjectYearChanged) {
        watchYearRef.current = watchYear;
      }
      if (isProjectTemplateChanged) {
        clearFieldRef.current = false; // Reset clearFieldRef
        projectTemplateIdRef.current = projectTemplate.id;
      }
      setProjectMilestones(milestones);
    }
  }, [
    disabledEndDateMilestoneType,
    projectTemplate?.id,
    projectTemplate?.milestones,
    watchEndDate,
    watchStartDate,
    watchYear
  ]);

  const defaultProjectManager = useMemo(
    () =>
      project?.projectManager
        ? {
            name: project?.projectManager?.name,
            value: project?.projectManager
          }
        : null,
    [project]
  );

  const projectManagerItems = useMemo(
    () =>
      projectManagers
        ?.map(projectManager => ({
          name: projectManager?.name,
          value: projectManager
        }))
        ?.sort((a, b) => a?.name?.localeCompare(b?.name)) ?? [],
    [projectManagers]
  );

  const templateLastMilestone = useMemo(() => {
    if (isTemplateSelected && projectTemplate?.milestones) {
      const endMilestone = projectTemplate?.milestones.find(
        milestone => milestone.type === MilestoneType.END
      );
      return endMilestone;
    }
    return null;
  }, [isTemplateSelected, projectTemplate?.milestones]);

  const projectEndDate = useMemo(() => {
    if (isTemplateSelected && projectMilestones) {
      const endMilestone = projectMilestones.find(
        milestone => milestone.type === MilestoneType.END
      );
      return endMilestone?.date;
    }
    if (project?.plannedCompletionDate) {
      return new Date(project.plannedCompletionDate);
    }
    return null;
  }, [isTemplateSelected, project?.plannedCompletionDate, projectMilestones]);

  const projectStartDate = useMemo(() => {
    if (showUpdateFieldsOnly) {
      return new Date(project?.startDate);
    }
    if (isTemplateSelected && projectMilestones) {
      const startMilestone = projectMilestones.find(
        milestone => milestone.type === MilestoneType.START
      );
      return startMilestone?.date;
    }

    return null;
  }, [
    isTemplateSelected,
    project?.startDate,
    projectMilestones,
    showUpdateFieldsOnly
  ]);

  useEffect(() => {
    if (!isTemplateSelected && !showUpdateFieldsOnly) {
      setValue("startDate", null);
      setValue("plannedCompletionDate", null);
    }
    if (!projectEndDate && !clearFieldRef.current && !showUpdateFieldsOnly) {
      clearFieldRef.current = true;
      setValue("plannedCompletionDate", null);
    }
    if (!projectStartDate && !clearFieldRef.current && !showUpdateFieldsOnly) {
      clearFieldRef.current = true;
      setValue("startDate", null);
    }
  }, [
    isTemplateSelected,
    projectEndDate,
    projectStartDate,
    setValue,
    showUpdateFieldsOnly
  ]);

  const getMinMaxDate = useCallback(
    (type = "min") => {
      if (!isTemplateSelected) {
        if (type === "min" && watchStartDate) {
          return watchStartDate;
        }
        if (type === "max" && watchEndDate) {
          return watchEndDate;
        }
        return null;
      }

      const standardMilestones = projectMilestones.filter(
        milestone => milestone.type === MilestoneType.STANDARD
      );
      const endMilestone = projectMilestones.find(
        milestone => milestone.type === MilestoneType.END
      );
      if (type === "min") {
        return standardMilestones.length
          ? standardMilestones[standardMilestones.length - 1]?.date
          : projectMilestones[projectMilestones.length - 2]?.date;
      }
      if (type === "max") {
        return standardMilestones.length
          ? standardMilestones[0]?.date
          : projectMilestones[1]?.date;
      }
      return endMilestone?.date;
    },
    [isTemplateSelected, projectMilestones, watchEndDate, watchStartDate]
  );

  //Reset form fields when config changes (this clears validation checks applied by schema validation linked to previous config)
  useEffect(() => {
    reset(fieldValues => ({ ...fieldValues }), { keepValues: true });
  }, [
    enableEntities,
    enableProjectManager,
    enableCopyEngagementUsers,
    enableProjectYear,
    enableProjectExternalId,
    enableProjectQueries,
    watchEngagementType,
    reset
  ]);

  const shouldDisableEndDate = useCallback(() => {
    return (
      showUpdateFieldsOnly ||
      (templateLastMilestone &&
        disabledEndDateMilestoneType.includes(
          templateLastMilestone?.calculator?.type
        ))
    );
  }, [
    disabledEndDateMilestoneType,
    showUpdateFieldsOnly,
    templateLastMilestone
  ]);
  const projectFormError = useMemo(() => {
    if (isError) {
      return error;
    }
    if (fetchExternalError) {
      return fetchExternalError;
    }
    return null;
  }, [isError, error, fetchExternalError]);

  const getProjectYearValue = useCallback(() => {
    if (project?.year) {
      return project.year;
    }
    return crudFormMode === systemConstants.project.crudFormMode.update
      ? null
      : new Date().getFullYear().toString();
  }, [crudFormMode, project.year]);

  return (
    <Stack gap="300" width="100">
      {projectFormError && (
        <ErrorBox message={getErrorMessage(projectFormError, t)} />
      )}
      {/* Key information */}
      <BoxTemplate title={title} subtext={subText}>
        <Stack gap="300">
          {/* Used for adding information to formState, thats relevant for validation */}
          <Form.HiddenInput
            key="entitiesEnabled"
            name="entitiesEnabled"
            value={enableEntities}
          />

          <InputContainer>
            <SelectClientDropdown
              disabled={showUpdateFieldsOnly}
              name="client"
              isFormField
              required
              defaultValue={
                project?.client && {
                  name: project?.client?.name,
                  value: project?.client
                }
              }
            />

            <Form.TextField
              name="projectName"
              label={t("common:ui.projects.name.label")}
              required
              defaultValue={project?.name}
            />
            <SelectEngagementTypeDropdown
              onChange={handleEngagementTypeChange}
              isFormField
              required
              hideIfOne
              disablePreselectDefault={true}
              disabled={showUpdateFieldsOnly || isTemplateSelected}
              defaultValue={project?.engagementType?.id}
            />
            {enableProjectYear && (
              <SelectProjectYearField
                name="year"
                label={t("common:ui.projects.year.label")}
                required
                defaultValue={getProjectYearValue()}
              />
            )}

            {enableProjectManager && (
              <Form.Dropdown
                name="projectManager"
                label={t("common:ui.projects.manager")}
                items={projectManagerItems}
                defaultValue={defaultProjectManager}
                required
              />
            )}
            <Form.DateField
              name="startDate"
              label={startDateLabel}
              maxDate={getMinMaxDate("max")}
              required
              onlyShowDescriptionOnOverflow
              disabled={showUpdateFieldsOnly}
              defaultValue={projectStartDate}
              labelDescription={startDateLabel}
            />
            <Form.DateField
              name="plannedCompletionDate"
              label={endDateLabel}
              required
              onlyShowDescriptionOnOverflow
              minDate={getMinMaxDate("min")}
              disabled={shouldDisableEndDate()}
              defaultValue={projectEndDate}
              labelDescription={endDateLabel}
            />
            {enableProjectExternalId && (
              <Form.TextField
                name="externalId"
                label={t("common:ui.projects.externalId.label")}
                defaultValue={project?.externalId}
              />
            )}
          </InputContainer>

          {enableEntities && (
            <UploadProjectEntitiesFormField
              name="entities"
              defaultValue={project?.entities}
              required
              disabled={showUpdateFieldsOnly}
            />
          )}

          {enableCopyEngagementUsers && (
            <Form.Checkbox
              name="copyEngagementUsers"
              label={t("common:ui.projects.copyUsers")}
              defaultValue={true}
            />
          )}
        </Stack>
      </BoxTemplate>
      {enableProjectQueries && (
        <ProjectFormQueries
          projectTemplate={projectTemplate}
          clientUsers={clientUsers}
          hostUsers={hostUsers}
          entitiesEnabled={enableEntities}
        />
      )}
    </Stack>
  );
}

ProjectForm.defaultProps = {
  enableEntities: false,
  enableExternalEntities: false,
  enableProjectManager: false,
  enableProjectYear: false,
  enableProjectExternalId: false,
  showUpdateFieldsOnly: false,
  enableProjectQueries: false
};

ProjectForm.propTypes = {
  project: PropTypes.object,
  enableEntities: PropTypes.bool,
  enableExternalEntities: PropTypes.bool,
  enableProjectManager: PropTypes.bool,
  enableProjectYear: PropTypes.bool,
  enableProjectExternalId: PropTypes.bool,
  enableCopyEngagementUsers: PropTypes.bool,
  isTemplateSelected: PropTypes.bool,
  projectTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    version: PropTypes.string,
    milestones: PropTypes.any,
    queries: PropTypes.any
  }),
  enableProjectQueries: PropTypes.bool,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  projectManagers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  showUpdateFieldsOnly: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.object,
  handleEngagementTypeChange: PropTypes.func,
  title: PropTypes.string,
  subText: PropTypes.string,
  crudFormMode: PropTypes.oneOf(
    Object.values(systemConstants.project.crudFormMode)
  )
};

export default ProjectForm;
