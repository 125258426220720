import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const queryResponseService = createApi({
  reducerPath: "queryResponseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["QueryResponse"],
  endpoints: builder => ({
    getQueryResponse: builder.query<unknown[], { queryId: number }>({
      query: ({ queryId }) => `/api/queries/${queryId}/queryResponses`,
      providesTags: ["QueryResponse"]
    }),
    addQueryResponse: builder.mutation({
      query: ({ queryResponse }) => ({
        url: "/api/queryResponse",
        method: "POST",
        body: queryResponse
      }),
      invalidatesTags: ["QueryResponse"]
    }),
    updateQueryResponse: builder.mutation({
      query: ({ queryResponse }) => ({
        url: `/api/queryResponse/${queryResponse.id}`,
        method: "PUT",
        body: queryResponse
      }),
      invalidatesTags: ["QueryResponse"]
    })
  })
});

export const {
  useGetQueryResponseQuery,
  useAddQueryResponseMutation,
  useUpdateQueryResponseMutation,
  util: queryResponseUtil
} = queryResponseService;
