import { configureStore } from "@reduxjs/toolkit";

import { manageDocumentUploadsConstants } from "@shared/constants";
import { rtkErrorLogger } from "@shared/middleware/rtkErrorHandler";
import { actionItemService } from "@shared/services/actionItemService";
import { actionItemTypesService } from "@shared/services/actionItemTypesService";
import { aiCleaningAssistantService } from "@shared/services/aiCleaningAssistantService";
import { auditService } from "@shared/services/auditService";
import { cleaningTemplateService } from "@shared/services/cleaningTemplateService";
import { clientProjectsService } from "@shared/services/clientProjectsService";
import { clientService } from "@shared/services/clientService";
import { cmsService } from "@shared/services/cmsService";
import { documentMimesService } from "@shared/services/documentMimesService";
import { documentService } from "@shared/services/documentService";
import { engagementTypeService } from "@shared/services/engagementTypeService";
import { engagementsService } from "@shared/services/engagementsService";
import { excelDocumentService } from "@shared/services/excelDocumentService";
import { featuresService } from "@shared/services/featuresService";
import { filtersService } from "@shared/services/filtersService";
import { footerService } from "@shared/services/footerService";
import { insightsService } from "@shared/services/insightsService";
import { interactiveReportService } from "@shared/services/interactiveReportService";
import { jsonEditorService } from "@shared/services/jsonEditorService";
import { newsService } from "@shared/services/newsService";
import { projectDashboardService } from "@shared/services/projectDashboardService";
import { projectDocumentService } from "@shared/services/projectDocumentService";
import { projectMemberService } from "@shared/services/projectMemberService";
import { projectService } from "@shared/services/projectService";
import { projectTagService } from "@shared/services/projectTagService";
import { projectTemplateService } from "@shared/services/projectTemplateService";
import { queryResponseService } from "@shared/services/queryResponseService";
import { riskService } from "@shared/services/riskService";
import { rolesService } from "@shared/services/roleService";
import { smartformService } from "@shared/services/smartformService";
import { tokenService } from "@shared/services/tokenService";
import { userService } from "@shared/services/userService";

import rootReducer from "../reducers";

export const middlewares = () => [
  actionItemService.middleware,
  actionItemTypesService.middleware,
  aiCleaningAssistantService.middleware,
  cleaningTemplateService.middleware,
  clientProjectsService.middleware,
  clientService.middleware,
  cmsService.middleware,
  documentService.middleware,
  documentMimesService.middleware,
  engagementsService.middleware,
  engagementTypeService.middleware,
  excelDocumentService.middleware,
  featuresService.middleware,
  filtersService.middleware,
  footerService.middleware,
  insightsService.middleware,
  interactiveReportService.middleware,
  projectDashboardService.middleware,
  projectDocumentService.middleware,
  projectTagService.middleware,
  projectMemberService.middleware,
  queryResponseService.middleware,
  tokenService.middleware,
  newsService.middleware,
  riskService.middleware,
  projectService.middleware,
  projectTemplateService.middleware,
  rolesService.middleware,
  smartformService.middleware,
  userService.middleware,
  jsonEditorService.middleware,
  auditService.middleware,
  rtkErrorLogger
];

//this only for non prod env, and bitbucket is slow and need more time
const warnAfter = process.env.NODE_ENV === "development" ? 32 : 64;

export const middlewareOptions = {
  immutableCheck: {
    //TODO: remove this exception and fix it
    ignoredPaths: [
      "manageDocumentUploads.uploadingDocuments",
      "manageExcelDocument.download",
      "manageExcelDocument.previousDownloaded"
    ],
    warnAfter
  },
  serializableCheck: {
    ignoredPaths: [
      "manageExcelDocument.download",
      "manageExcelDocument.previousDownloaded",
      "manageDocumentUploads.uploadingDocuments"
    ],
    ignoreActions: [manageDocumentUploadsConstants.UPDATE_UPLOAD_PROGRESS],
    warnAfter
  }
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware(middlewareOptions).concat(middlewares()),
  devTools: process.env.NODE_ENV !== "production"
});
