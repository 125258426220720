import React from "react";

import { useTranslation } from "react-i18next";

import { ProjectTemplateSummaryDto } from "@shared/services/projectTemplateService.ts";

import { Box, Inline, Stack } from "@fermions";

import ProjectTemplateCard from "@organisms/ProjectTemplatePanel/ProjectTemplateCard/ProjectTemplateCard";

import "./ProjectTemplatePanel.scss";

export type ProjectTemplateId = "blank" | number;

interface ProjectTemplatePanelProps {
  projectTemplates?: ProjectTemplateSummaryDto[];
  selectedTemplateId?: ProjectTemplateId;
  onClickTemplate: (templateId: ProjectTemplateId) => void;
}

const ProjectTemplatePanel = ({
  projectTemplates,
  selectedTemplateId,
  onClickTemplate
}: ProjectTemplatePanelProps) => {
  const { t } = useTranslation();
  return (
    <Stack className="project-template-panels" gap="200" width="100">
      <Inline className="project-template-panels__top" width="100">
        <Box className="project-template-panels__top-title">
          {t("common:ui.projectTemplate.panel.selectTemplate.callToAction")}
        </Box>
        {/* Note: CREATE PROJECT TEMPLATE button to be implemented in future ticket.*/}
        {/* <Button
          label={t("common:ui.projectTemplate.panel.create.button.title")}
          variant={ButtonVariant.TEXT}
          iconName="add"
          onClick={() => {}}
        /> */}
      </Inline>
      <Inline className="project-template-panels__scroll-container" gap="300">
        <ProjectTemplateCard
          key="blank"
          isBlank
          isSelected={selectedTemplateId === "blank"}
          onClick={() => onClickTemplate("blank")}
        />
        {projectTemplates?.map(template => (
          <ProjectTemplateCard
            key={template.id}
            template={template}
            isSelected={selectedTemplateId === template.id}
            onClick={() => onClickTemplate(template.id)}
          />
        ))}
      </Inline>
    </Stack>
  );
};

export default ProjectTemplatePanel;
