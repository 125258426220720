import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { authActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { useGetProfileQuery, useUpdateProfileMutation } from "@shared/services";

export function useUserProfile() {
  const dispatch = useDispatch();
  const authentication = useSelector(state => state.authentication);
  const [userProfileError, setUserProfileError] = useState();
  const [requireLogout, setRequireLogout] = useState(false);
  const [userFormConfig, setUserFormConfig] = useState();

  useEffect(() => {
    // call hostBrandApi to get the latest host details with formConfig
    if (!authentication.host?.host?.properties?.formsConfig) {
      dispatch(authActions.getHostWithBrandDetails());
    }
  }, [authentication.host?.host?.properties?.formsConfig, dispatch]);

  const { data: profile, error } = useGetProfileQuery(authentication.user.id, {
    skip: !authentication?.user?.id
  });

  const userProfile = {
    ...profile,
    mobileCountryCode: systemConstants.defaultCountryCode,
    businessCountryCode: systemConstants.defaultCountryCode
  };

  const [doUpdate, { isSuccess, isLoading: isUserProfileUpdating }] =
    useUpdateProfileMutation();

  const updateUserProfile = useCallback(
    async updatedProfile => {
      const { requireLogout } = await doUpdate(updatedProfile).unwrap();
      setRequireLogout(requireLogout);
    },
    [doUpdate]
  );

  useEffect(() => {
    if (error) {
      setUserProfileError(error);
    }
  }, [error]);

  useEffect(
    () =>
      setUserFormConfig(
        authentication.host?.host?.properties?.formsConfig?.userProfileFields ??
          []
      ),
    [authentication.host?.host?.properties?.formsConfig?.userProfileFields]
  );

  return {
    submitted: isSuccess,
    userFormConfig,
    userProfile,
    error: userProfileError,
    updateUserProfile,
    isUserProfileUpdating,
    requireLogout
  };
}
