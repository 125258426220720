import React from "react";

import { useTranslation } from "react-i18next";

import ModalForm from "@components/molecules/ModalForm";

interface ActionConfirmationPropTypes {
  handleSubmit: () => void;
  handleCancel: () => void;
  title: string;
  message: string;
  error: string;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
}

const ActionConfirmation = (props: ActionConfirmationPropTypes) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    handleCancel,
    error,
    title,
    message,
    submitButtonLabel,
    cancelButtonLabel
  } = props;

  return (
    <ModalForm
      title={t(title)}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      submitLabel={t(submitButtonLabel ?? "common:ui.forms.submit.label")}
      cancelLabel={t(cancelButtonLabel ?? "common:ui.forms.cancel.label")}
      errorMessage={error || null}
    >
      <div>{`${t(message)}`}</div>
    </ModalForm>
  );
};

export default ActionConfirmation;
