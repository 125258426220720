import React, { useEffect } from "react";

import Popup from "@shared/components/popup/Popup";

import ActionConfirmation from "@organisms/QueryActions/ActionConfirmation";

interface ContinueConfirmationPropTypes {
  closeExpireModal: () => void;
  sessionLeftSec: number;
  setTriggerLogout: (trigger: boolean) => void;
  t: (key: string, params?: Record<string, unknown>) => string;
}

export const ContinueConfirmation = ({
  closeExpireModal,
  sessionLeftSec,
  setTriggerLogout,
  t
}: ContinueConfirmationPropTypes) => {
  useEffect(() => {
    if (sessionLeftSec <= 0) {
      setTriggerLogout(true);
    }
  }, [sessionLeftSec, setTriggerLogout]);
  return (
    <Popup
      visibility={true}
      handleOutsideClick={() => {
        closeExpireModal();
      }}
      width="50rem"
    >
      <ActionConfirmation
        title={t("common:ui.modals.header.sessionTimeout.label")}
        message={t("common:ui.modals.message.sessionTimeout.label", {
          sessionLeftSec: sessionLeftSec
        })}
        handleSubmit={() => {
          closeExpireModal();
        }}
        handleCancel={() => {
          setTriggerLogout(true);
        }}
        submitButtonLabel={t("common:ui.modals.button.keepWorking.label")}
        cancelButtonLabel={t("ui.modals.button.logout.label")}
        error=""
      />
    </Popup>
  );
};
