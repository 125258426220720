import React, { useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";

import { ProjectTemplateDto } from "@shared/services/projectTemplateService.ts";

import { QUERY_TYPE_ICON } from "@app/helpers";
import { useDataTable } from "@app/hooks";
import { CreateProjectTemplateActionItemsMethod } from "@app/types/ProjectTemplate";

import { Inline, Stack } from "@fermions";

import { Icon } from "@atoms/Icon";

import { InputContainer } from "@templates/InputContainer";

import Form from "@components/atoms/Form";
import BoxTemplate from "@components/templates/BoxTemplate";

import DataTable from "../DataTable";

interface ProjectFormQueriesProps {
  projectTemplate: ProjectTemplateDto;
  hostUsers?: object[];
  clientUsers?: object[];
  entitiesEnabled?: boolean;
}

interface BasicUserInfo {
  id: number;
  name: string;
}

export const ProjectFormQueries = ({
  projectTemplate,
  // TODO: add back with requested by
  // currentUser,
  hostUsers,
  clientUsers,
  entitiesEnabled
}: ProjectFormQueriesProps) => {
  const { t } = useTranslation();
  const { createColumn } = useDataTable(projectTemplate.queries);
  const tableRef = useRef();

  const columns = useMemo(() => {
    const updatedColumns = [
      createColumn({
        Header: t("requests:requests.descriptionLabel"),
        accessor: "description"
      }),
      createColumn({
        Header: t(
          "requests:requests.ui.populateRequestForm.labelSelectRequestType",
          {
            requestLongNameSingular: t("requests:requests.longName")
          }
        ),
        accessor: "queryType",
        className: "queryType",
        Cell: ({
          cell
        }: {
          cell: { value: { name: string; type: string } };
        }) => {
          const queryType = cell.value;
          const icon: string | undefined =
            QUERY_TYPE_ICON[queryType?.type as keyof typeof QUERY_TYPE_ICON];
          return (
            <Inline gap="100" alignment="left">
              {queryType?.name}
              {icon && (
                <Icon
                  className="data-table__data-query-type__icon"
                  name={icon}
                  hoverElement={queryType?.name}
                />
              )}
            </Inline>
          );
        }
      })
    ];
    if (entitiesEnabled) {
      updatedColumns.push(
        createColumn({
          Header: t("requests:projectTemplate.createMethod.columnHeaderTitle", {
            context: "Requests"
          }),
          accessor: "createMethod",
          className: "createProjectTemplateActionItemsMethod",

          Cell: ({ cell }: { cell: { value: string } }) => {
            return cell?.value ? (
              <Inline gap="100" alignment="left">
                {t("requests:projectTemplate.createMethod.requests", {
                  context:
                    cell.value ??
                    CreateProjectTemplateActionItemsMethod.OneWithAllEntities
                })}
              </Inline>
            ) : (
              <></>
            );
          }
        })
      );
    }
    return updatedColumns;
  }, [createColumn, entitiesEnabled, t]);

  const hostUsersList = useMemo(
    () =>
      (hostUsers as Array<BasicUserInfo>)?.map(user => ({
        id: user.id,
        name: user.name,
        tag: t("common:ui.projects.members.host")
      })) ?? [],
    [hostUsers, t]
  );

  const clientUsersList = useMemo(
    () =>
      (clientUsers as Array<BasicUserInfo>)?.map(user => ({
        id: user.id,
        name: user.name,
        tag: t("common:ui.projects.members.client")
      })) ?? [],
    [clientUsers, t]
  );

  const clientAndHostUsersList = useMemo(
    () => [...clientUsersList, ...hostUsersList],
    [clientUsersList, hostUsersList]
  );
  return (
    <BoxTemplate
      title={t("common:ui.project.title_formTitle_queries")}
      subtext={t("common:ui.project.title_formSubText_queries")}
    >
      <Stack gap="300">
        <InputContainer>
          {/* TODO: Add back in future ticket - for now requestedBy is current user */}
          {/* <Form.Dropdown
            label={t("requests:requests.configured.fields.createdBy.label")}
            description="Choose a sender for the initial requests. This user will be added to your project."
            name="requestedBy"
            items={hostUsersList ?? []}
            defaultValue={currentUser}
            required
            sortComparator={() => 0}
          /> */}
          <Form.Dropdown
            label={t("requests:requests.configured.fields.assignedTo.label")}
            description={t(
              "requests:requests.configured.fields.assignedTo.projectTemplate.description"
            )}
            name="assignedTo"
            items={clientAndHostUsersList}
            disabled={!clientUsers?.length}
            required
            sortComparator={() => 0}
          />
          <Form.Multiselect
            label={t("requests:requests.configured.fields.copiedTo.label")}
            description={t(
              "requests:requests.configured.fields.copiedTo.projectTemplate.description"
            )}
            name="copiedTo"
            items={clientAndHostUsersList}
            disabled={!clientUsers?.length}
            allowUndefined
            sortComparator={() => 0}
          />
        </InputContainer>
        <DataTable
          ref={tableRef}
          className="action-items-table"
          columns={columns}
          data={projectTemplate.queries}
          fitContentWidth
        />
      </Stack>
    </BoxTemplate>
  );
};
