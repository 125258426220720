import { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { authActions, resetActions } from "@shared/actions";
import { useDocumentUploadInProgress } from "@shared/hooks";
import { useUpdateExtendMutation } from "@shared/services/authService.ts";

export function useLogout() {
  const authentication = useSelector(state => state.authentication);
  const { documentUploadInProgress } = useDocumentUploadInProgress();
  const [triggerLogout, setTriggerLogout] = useState(false);
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [sessionExpiryInSec, setSessionExpiryInSec] = useState(Infinity);
  const [updateLastAccess] = useUpdateExtendMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SESSION_EXPIRY_COUNTDOWN_SECONDS = 60;
  const USER_ACTIVITY_CHECK_SECONDS = 60 * 5;

  const maxTimeKey = "AutoLogoutMaxTime";
  const showExpireModalKey = "ShowExpireModal";
  const logoutKey = "Logout";
  const isUserActive = "IsUserActive";

  const events = useMemo(
    () => ["mousemove", "mousedown", "touchstart", "click", "keydown"],
    []
  );

  const autoLogoutIdleTimer = authentication.user.autoLogoutIdleTimer;

  const newMaxTime = useCallback(
    () => Date.now() + (autoLogoutIdleTimer?.value ?? 0) * 60 * 1000,
    [autoLogoutIdleTimer?.value]
  );

  const updateMaxTime = useCallback(() => {
    localStorage.setItem(maxTimeKey, newMaxTime().toString());
  }, [newMaxTime]);

  const checkIfUserIsActive = useCallback(async () => {
    const shouldUpdate = localStorage.getItem(isUserActive);
    if (
      (shouldUpdate === "true" || documentUploadInProgress) &&
      !showExpireModal
    ) {
      await updateLastAccess()
        .unwrap()
        .then(() => {
          updateMaxTime();
          localStorage.setItem(isUserActive, "false");
        });
    }
  }, [
    documentUploadInProgress,
    showExpireModal,
    updateLastAccess,
    updateMaxTime
  ]);

  const handleEvents = useCallback(() => {
    updateMaxTime();
    if (localStorage.getItem(isUserActive) === "false") {
      localStorage.setItem(isUserActive, "true");
    }
  }, [updateMaxTime]);

  const cancelUpdateTimeEvents = useCallback(() => {
    events.forEach(evt => document.removeEventListener(evt, handleEvents));
  }, [events, handleEvents]);

  const checkMaxTime = useCallback(() => {
    if (documentUploadInProgress) {
      updateMaxTime();
      return;
    }
    const now = Date.now();
    const maxTime = localStorage.getItem(maxTimeKey);
    if (
      (maxTime && Number(maxTime) < now) ||
      localStorage.getItem(logoutKey) === "true"
    ) {
      setTriggerLogout(true);
    }
  }, [documentUploadInProgress, updateMaxTime, maxTimeKey]);

  const logout = useCallback(() => {
    cancelUpdateTimeEvents();
    if (Number(localStorage.getItem(maxTimeKey)) < Date.now()) {
      localStorage.setItem(maxTimeKey, "0");
    }
    dispatch(authActions.logout());
    dispatch(resetActions.reset());
    localStorage.setItem(logoutKey, "true");
    window.location.reload();
  }, [cancelUpdateTimeEvents, dispatch]);

  const closeExpireModal = useCallback(() => {
    localStorage.setItem(showExpireModalKey, "false");
    checkIfUserIsActive();
    setShowExpireModal(false);
    setSessionExpiryInSec(Infinity);
    handleEvents();
    events.forEach(evt => document.addEventListener(evt, handleEvents));
  }, [checkIfUserIsActive, events, handleEvents]);

  useEffect(() => {
    const timer = setInterval(() => {
      const currentSessionTime = localStorage.getItem(maxTimeKey);
      const isSessionExpiring =
        Number(currentSessionTime) - Date.now() <
        SESSION_EXPIRY_COUNTDOWN_SECONDS * 1000;
      checkMaxTime();
      const localModalValue = localStorage.getItem(showExpireModalKey);
      setShowExpireModal(localModalValue === "true");
      if (isSessionExpiring) {
        setSessionExpiryInSec(
          Math.floor((Number(currentSessionTime) - Date.now()) / 1000)
        );
      }

      if (isSessionExpiring && !showExpireModal) {
        setShowExpireModal(true);
        localStorage.setItem(showExpireModalKey, "true");
        cancelUpdateTimeEvents();
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [
    cancelUpdateTimeEvents,
    checkMaxTime,
    events,
    logout,
    showExpireModal,
    updateMaxTime
  ]);

  useEffect(() => {
    if (triggerLogout && !documentUploadInProgress) {
      logout();
    }
  }, [
    triggerLogout,
    documentUploadInProgress,
    logout,
    navigate,
    cancelUpdateTimeEvents
  ]);

  useEffect(() => {
    if (!autoLogoutIdleTimer?.enabled || showExpireModal) {
      return;
    }
    localStorage.setItem(showExpireModalKey, "false");
    updateMaxTime();
    localStorage.setItem(logoutKey, "false");
    localStorage.setItem(isUserActive, "false");

    setInterval(() => {
      checkIfUserIsActive();
    }, 1000 * USER_ACTIVITY_CHECK_SECONDS);

    events.forEach(evt => document.addEventListener(evt, handleEvents));

    return () =>
      events.forEach(evt => document.removeEventListener(evt, handleEvents));
  }, [
    authentication.user.autoLogoutIdleTimer,
    documentUploadInProgress,
    autoLogoutIdleTimer?.enabled,
    updateMaxTime,
    checkMaxTime,
    events,
    showExpireModal,
    handleEvents,
    checkIfUserIsActive
  ]);
  return {
    logout,
    setTriggerLogout,
    showExpireModal,
    closeExpireModal,
    sessionExpiryInSec
  };
}
