import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useGetClientsQuery, useUrlHash } from "@shared/hooks";

import { DropdownInput } from "@molecules/inputs";

import Form from "@components/atoms/Form";

function SelectClientDropdown(props) {
  const { t } = useTranslation();
  const {
    name,
    onChange,
    hideIfOne,
    isFormField,
    defaultValue,
    required,
    disabled
  } = props;
  const { data: clients } = useGetClientsQuery({});
  const { urlHashDetail, updateUrlHash } = useUrlHash();
  const [selectedClient, setSelectedClient] = useState(defaultValue);

  useEffect(() => {
    if (selectedClient?.value) {
      onChange?.(selectedClient.value);
    }
  }, [onChange, selectedClient]);

  useEffect(() => {
    const clientId = urlHashDetail.get("clientId");
    if (clientId) {
      const matchingClient = clients?.find(client => client.id == clientId);
      if (matchingClient) {
        setSelectedClient({
          value: { id: matchingClient.id, name: matchingClient.name },
          name: matchingClient.name
        });
      }
    }
  }, [clients, urlHashDetail]);

  const dropdownItems = useMemo(
    () =>
      clients?.map(c => ({
        name: c.name,
        value: { id: c.id, name: c.name }
      })),
    [clients]
  );

  const onClientChange = useCallback(
    value => {
      setSelectedClient(value);
      const urlClientId = urlHashDetail.get("clientId");
      if (urlClientId && urlClientId != value.id) {
        updateUrlHash("clientId", "");
      }
    },
    [updateUrlHash, urlHashDetail]
  );

  const clientDropdown = useMemo(() => {
    if (isFormField) {
      return (
        <Form.Dropdown
          name={name ?? "client"}
          label={t("stringFormat.propercase", {
            key: "common:clients.displayName"
          })}
          items={dropdownItems}
          defaultValue={selectedClient}
          onChange={onClientChange}
          required={required}
          disabled={disabled}
          showSearch
          placeholder={t("common:ui.forms.placeholder.selectionOption", {
            label: t("stringFormat.propercase", {
              key: "common:clients.displayName_indefinite",
              escapeArticle: true
            })
          })}
        />
      );
    }

    return (
      <DropdownInput
        label={t("stringFormat.propercase", {
          key: "common:clients.displayName"
        })}
        items={dropdownItems}
        value={selectedClient}
        disabled={disabled}
        showSearch
        onChange={setSelectedClient}
        required={required}
        placeholder={t("common:ui.forms.placeholder.selectionOption", {
          label: t("stringFormat.propercase", {
            key: "common:clients.displayName_indefinite",
            escapeArticle: true
          })
        })}
      />
    );
  }, [
    disabled,
    dropdownItems,
    isFormField,
    name,
    onClientChange,
    required,
    selectedClient,
    t
  ]);

  if (hideIfOne && clients?.length === 1) {
    return isFormField ? (
      <Form.HiddenInput
        name={name ?? "client"}
        value={{ name: clients[0].name, value: clients[0] }}
      />
    ) : (
      <></>
    );
  }

  return <>{clientDropdown}</>;
}

SelectClientDropdown.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  hideIfOne: PropTypes.bool,
  isFormField: PropTypes.bool,
  defaultValue: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SelectClientDropdown;
