import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  endpoints: builder => ({
    updateExtend: builder.mutation<void, void>({
      query: () => ({
        url: "/api/auth/extend",
        method: "POST"
      })
    })
  })
});

export const { useUpdateExtendMutation } = authApi;
