import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { CreateProjectTemplateActionItemsMethod } from "@app/types/ProjectTemplate";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export interface ProjectTemplateSummaryDto {
  id: number;
  name: string;
  engagementType: {
    id: number;
    name: string;
  };
  documentCount: number;
  milestoneCount: number;
  queryCount: number;
  duration: number;
  createQueriesMethod?: CreateProjectTemplateActionItemsMethod;
}

export interface ProjectTemplateDto {
  id: number;
  name: string;
  users: Array<number>;
  labels: Array<string>;
  queries: Array<{
    queryType: {
      id: number;
      name: string;
      key: string;
      type: string;
    };
    description: string;
    createMethod: CreateProjectTemplateActionItemsMethod;
  }>;
  documents: Array<{
    path: string;
    labels: Array<string>;
  }>;
  milestones: Array<{
    key: string;
    type: string;
    order: number;
    calculator: {
      day: number;
      type: string;
      month: number;
      yearAdjustment: number;
    };
    date: string;
  }>;
}

export const projectTemplateService = createApi({
  reducerPath: "projectTemplateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["ProjectTemplate"],
  endpoints: builder => ({
    getAllProjectTemplate: builder.query<
      Array<ProjectTemplateSummaryDto>,
      void
    >({
      query: () => "/api/projectTemplates",
      providesTags: ["ProjectTemplate"]
    }),
    getProjectTemplateById: builder.query({
      query: ({ projectTemplateId }) =>
        `/api/projectTemplates/${projectTemplateId}`,
      providesTags: (_, __, { projectTemplateId }) => [
        { type: "ProjectTemplate", id: projectTemplateId }
      ]
    })
  })
});

export const { useGetAllProjectTemplateQuery, useGetProjectTemplateByIdQuery } =
  projectTemplateService;
