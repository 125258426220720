import React, { useCallback, useEffect, useMemo, useState } from "react";

import { isEmpty, set } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";

import { systemConstants } from "@shared/constants";
import { useToasts } from "@shared/hooks/useToasts";
import { useAddQueryResponseMutation } from "@shared/services";
import { useGetSupportedMimesQuery } from "@shared/services/documentMimesService";

import {
  convertEntitiesToIds,
  populateRelevantEntitiesByIdToArray
} from "@app/helpers/entity";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert";
import ModalForm from "@components/molecules/ModalForm";

import "./AddQueryResponse.scss";

const addFilesState = systemConstants.addFiles.state;
const queryTypes = systemConstants.project.queries.queryTypes;
const responseTypes = systemConstants.project.queries.responses.responseTypes;

const AddQueryResponse = props => {
  const { onSuccess, query, project } = props;
  const { t } = useTranslation();
  const { id: projectId } = project;
  const queryEntities = populateRelevantEntitiesByIdToArray(project, query);
  const { data: supportedDocumentMimes } = useGetSupportedMimesQuery({
    type: systemConstants.mimeDocumentType.document
  });
  const [errorMsg, setErrorMsg] = useState();
  const [
    addResponse,
    { isSuccess: isQueryResponseAdded, isLoading: isQueryResponseSaving }
  ] = useAddQueryResponseMutation();
  const [formData, setFormData] = useState(null);
  const [uploadState, setUploadState] = useState(addFilesState.add);
  const [showEntitiesMultiSelect, setShowEntitiesMultiSelect] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState(queryEntities);
  const [uploadData, setUploadData] = useState({});
  const { showDocumentNameModifiedToast } = useToasts();

  const addQueryResponse = useCallback(
    data => {
      const responseData = {
        ...data,
        queryId: props.query.id
      };
      if (
        props.responseType === responseTypes.approve ||
        props.responseType === responseTypes.reject
      ) {
        responseData.responseType = props.responseType;
      }

      addResponse({ queryResponse: responseData })
        .unwrap()
        .then(data => {
          if (data?.response?.documentModifiedNotification?.length) {
            data?.response?.documentModifiedNotification?.forEach(
              ({ originalName, newName }) => {
                showDocumentNameModifiedToast({
                  originalName,
                  newName
                });
              }
            );
          }
          onSuccess();
        })
        .catch(error => {
          setErrorMsg(error?.data?.key);
        });
    },
    [
      addResponse,
      onSuccess,
      props.query.id,
      props.responseType,
      showDocumentNameModifiedToast
    ]
  );

  const handleSelectEntities = e => {
    setSelectedEntities(
      e?.map(({ name, externalId }) => ({
        name,
        externalId
      }))
    );
  };

  const handleFilesChange = files => {
    setShowEntitiesMultiSelect(
      !isEmpty(files) &&
        project.configuration.entities?.enabled &&
        queryEntities?.length > 0
    );
  };

  const canAttachFiles = useMemo(
    () =>
      props.responseType === responseTypes.message &&
      [systemConstants.actionItemTypes.conversation].includes(
        props.queryConfig.type
      ),
    [props.queryConfig.type, props.responseType]
  );

  const uploadFiles = () => {
    setUploadState(addFilesState.upload);
  };

  const sendResponse = useCallback(
    (data, files) => {
      const formattedFiles = files?.map(file => {
        set(
          file,
          ["properties", "entities"],
          convertEntitiesToIds(selectedEntities)
        );
        return file;
      });

      const submitReqData = {
        ...data,
        files: formattedFiles || [],
        responseType: props.responseType,
        isDraft: false
      };
      addQueryResponse(submitReqData);
    },
    [addQueryResponse, props.responseType, selectedEntities]
  );

  const onFileUploadsCompleted = uploadedFiles => {
    setUploadState(addFilesState.finished);
    const newFiles = Object.keys(uploadedFiles).map(key => {
      const file = uploadedFiles[key];
      return {
        filePathId: file.filePathId,
        name: file.name,
        projectId: projectId
      };
    });
    sendResponse(formData, newFiles);
  };

  const onFileUploadsFailed = error => {
    setFormData(null);
    setErrorMsg(error);
    setUploadState(addFilesState.finished);
  };
  const handleSubmit = data => {
    setUploadData(data);
    const files = Object.values(data.files ?? {});
    if (!files.length) {
      setFormData({
        response: data.response
      });
      canAttachFiles
        ? uploadFiles()
        : sendResponse({
            response: data.response
          });
    }
  };

  useEffect(() => {
    const files = Object.values(uploadData?.files ?? {});
    if (files.length) {
      setUploadData({});
      setFormData({
        response: uploadData?.response
      });
      canAttachFiles
        ? uploadFiles()
        : sendResponse({
            response: uploadData?.response
          });
    }
  }, [canAttachFiles, sendResponse, uploadData?.files, uploadData?.response]);

  const getSubmitButtonLabel = () => {
    switch (props.responseType) {
      case responseTypes.approve:
        return t("common:ui.forms.approve.label");
      case responseTypes.reject:
        return t("common:ui.forms.reject.label");
      default:
        return t("common:ui.forms.save.label");
    }
  };

  const getMessageSubject = () => {
    if (props.responseType === responseTypes.message) {
      return (
        <span>
          <Trans
            i18nKey="requests:requests.responses.addResponse.subject"
            values={{ shortDescription: props.query?.description ?? "" }}
            context={
              props.queryType === queryTypes.approveRequest
                ? queryTypes.approveRequest
                : ""
            }
            shouldUnescape={true}
            components={{ bold: <strong /> }}
          />
        </span>
      );
    } else {
      return <></>;
    }
  };

  const getTitle = () => {
    switch (props.responseType) {
      case responseTypes.message:
        return props.queryType === queryTypes.approveRequest
          ? "Add a Message"
          : t("requests:requests.responses.addResponse.title");
      case responseTypes.reject:
        return "Reject Request";
      case responseTypes.approve:
        return "Send Approval";
      default:
        return "";
    }
  };

  const validationSchema = () => {
    return yup.object().shape({
      ...(props.responseType !== responseTypes.approve
        ? {
            response: yup
              .string()
              .required(
                t("requests:requests.responses.addResponse.messageLabel")
              )
          }
        : {})
    });
  };

  return (
    <ModalForm
      title={getTitle()}
      boxClassName="ot-add-responses"
      handleSubmit={handleSubmit}
      submitLabel={getSubmitButtonLabel()}
      handleCancel={props.onCancel}
      yupSchema={validationSchema()}
      disabled={isQueryResponseAdded || isQueryResponseSaving}
    >
      {errorMsg && (
        <div className="ot-add-response__contents-error-box">
          <InlineAlert type="error" message={t(errorMsg)} />
        </div>
      )}
      <div className="ot-add-response__contents-subject">
        {getMessageSubject()}
      </div>
      <div className="ot-form__add-text-document-response">
        <Form.TextArea
          label={t("requests:requests.responses.addResponse.messageLabel")}
          name="response"
          placeholder={t(
            "requests:requests.responses.addResponse.messageLabel"
          )}
          maxLength="8192"
          data-testid="message"
          required={props.responseType !== responseTypes.approve}
        />
      </div>
      {canAttachFiles && (
        <div className="ot-add-response__contents-attachments">
          <Form.UploadDocuments
            name={"files"}
            internalOnly={props.queryConfig.internalOnly}
            supportedDocumentMimes={supportedDocumentMimes}
            projectId={projectId}
            state={uploadState}
            onUploadsComplete={onFileUploadsCompleted}
            onUploadsFailed={onFileUploadsFailed}
            onChange={handleFilesChange}
          />
        </div>
      )}
      {showEntitiesMultiSelect && (
        <Form.Multiselect
          key="entities"
          name="entities"
          onChange={handleSelectEntities}
          label={t(`requests:requests.configured.fields.entities.label`)}
          defaultValue={selectedEntities?.map(entity => ({
            ...entity,
            value: entity.name
          }))}
          items={queryEntities}
        />
      )}
    </ModalForm>
  );
};

export default AddQueryResponse;
