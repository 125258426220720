import logo from "../assets/images/martinITLogo.png";

export const systemConstants = {
  support: {
    email: "",
    phone: "",
    message: ""
  },
  viewScopeType: {
    project: "project",
    report: "report"
  },
  copyActionItemOption: {
    key: "copyActionItems"
  },

  actionItemTypes: {
    websheet: "WEBSHEET",
    conversation: "CONVERSATION",
    smartForm: "SMARTFORM"
  },

  requestPageFilter: {
    selectType: {
      singleChoice: "singleChoice",
      multiChoice: "multiChoice"
    },
    slot: {
      main: "main",
      secondary: "secondary"
    }
  },

  filterParams: {
    open: "open",
    responded: "responded"
  },

  smartForm: {
    responseType: {
      document: "document",
      websheet: "websheet"
    },
    updatedBy: {
      ot: "OT"
    },
    template: {
      default: "default",
      dataCollection: "dataCollection",
      adhocRequest: "adhocRequest"
    }
  },
  article: {
    status: {
      active: "ACTIVE",
      inactive: "INACTIVE"
    }
  },
  mimeDocumentType: {
    image: "image",
    document: "document"
  },
  mimes: {
    image: ["jpeg", "jpg", "png"],
    document: ["xlsx", "docx", "pdf", "csv"]
  },
  search: {
    type: {
      documents: "Documents",
      requests: "Queries"
    }
  },
  popup: {
    popupCloseTimeoutForTest: 500
  },
  externalAuthProviders: {
    google: {
      key: "google"
    },
    azure: {
      key: "azure"
    }
  },
  externalLinkObjectType: {
    clientDataRepository: "client-data-repository",
    projectNotification: "project-notification",
    projectTodo: "project-todo",
    projectRequestResponse: "project-request-response",
    projectRequest: "project-request",
    projectQueryList: "project-query-list",
    projectDataRepository: "project-data-repository"
  },
  defaultCountryCode: "+61",
  financialYearStartsOn: 7,
  logo: logo,
  features: {
    projectNotes: "Project Notes",
    projectRisks: "Project Risks",
    projectRequests: "Project Queries",
    projectDocuments: "Project Documents",
    googleAuthentication: "Google Authentication",
    taskTemplates: "Task Templates",
    globalTags: "Global Tags",
    clientTags: "Client Tags",
    permanentFiles: "Permanent Files",
    editXlsx: "Edit XLSX",
    dataExtraction: "Data Extraction",
    manageNews: "Manage News",
    interactiveReport: "Interactive Report",
    aiReporting: "AI Reporting",
    aiCLeaningAssistant: "AI Cleansing Assistant",
    indicatorPill: "Indicator Pill",
    downloadWebsheet: "Download Websheet",
    insights: "Insights",
    engagements: "Engagements",
    projectManagerField: "Project Manager Field",
    engagementManagerField: "Engagement Manager Field",
    roles: "Roles",
    requestViewSwitcher: "Request View Switcher",
    requestViewFilters: "Request View Filters",
    oneteamAI: "OneTeam AI",
    externalHostUsers: "External Host Users",
    externalClientUsers: "External Client Users",
    externalEntities: "External Entities"
  },
  loginFailAttempts: 3,
  serverURL: process.env.REACT_APP_ONETEAM_BE_SERVER,
  resetPasswordTokenExpiry: 24,
  mfaCodeLength: 6,
  document_status_uploaded: "UPLOADED",
  mediaBreakpoints: {
    phone: 720,
    phoneLand: 567,
    tabPort: 768,
    tabLand: 1089
  },
  addFiles: {
    state: {
      add: "add",
      upload: "upload",
      finished: "finished"
    },
    attachedFile: {
      state: {
        selected: "selected",
        uploading: "uploading",
        uploaded: "uploaded",
        uploadFailed: "uploadFailed",
        attached: "attached"
      }
    }
  },
  colorCode: {
    primary: "#1665d8",
    success: "#47b881",
    warning: "#ff7542",
    disabled: "#f0f0f0"
  },
  dashboard: {
    actionItemsFilterType: {
      myUpcomingItems: "myUpcomingItems",
      myPastDueItems: "myPastDueDateItems",
      myActiveItems: "myActiveItems",
      myClosedItems: "myClosedItems",
      myOpenItems: "myOpenItems",
      myRespondedItems: "myRespondedItems",
      myTeamOpenItems: "myTeamOpenItems",
      myTeamRespondedItems: "myTeamsRespondedItems",
      myTeamActiveItems: "myTeamActiveItems",
      myTeamClosedItems: "myTeamClosedItems",
      myTeamsUpcomingItems: "myTeamUpcomingItems",
      myTeamsPastDueItems: "myTeamsPastDueDateItems"
    },
    recentDocuments: 8,
    incompleteTodosCount: 10,
    openQueriesCount: 10,
    tasksCount: 10
  },
  headerMenu: {
    dashboard: "Dashboard",
    documents: "Documents",
    projects: "Projects",
    engagements: "Engagements",
    admin: "Admin",
    logout: "Logout"
  },
  engagement: {
    status: {
      active: "ACTIVE",
      completed: "COMPLETED",
      inactive: "INACTIVE"
    }
  },
  client: {
    status: {
      active: "ACTIVE",
      inactive: "INACTIVE"
    }
  },
  user: {
    status: {
      active: "ACTIVE",
      inactive: "INACTIVE"
    }
  },
  roles: {
    engagementManager: "Engagement Manager",
    teamMember: "Team Member",
    clientAdmin: "Client Admin",
    clientUser: "Client User"
  },
  pagination: {
    itemCountPerPage: 100
  },
  host: {
    document: {
      status: {
        active: "ACTIVE",
        archived: "ARCHIVED"
      }
    }
  },
  engagementType: {
    events: {
      refreshNewsItem: "REFRESH_ENGAGEMENT_TYPE_NEWS"
    }
  },
  project: {
    events: {
      projects: {
        refreshProjects: "REFRESH_PROJECTS",
        refreshProjectMilestones: "REFRESH_PROJECT_MILESTONES",
        refreshProjectMembers: "REFRESH_PROJECT_MEMBERS",
        refreshProjectQueries: "REFRESH_PROJECT_QUERIES",
        refreshProjectTodos: "REFRESH_PROJECT_TODOS",
        refreshProject: "REFRESH_PROJECT"
      }
    },
    status: {
      draft: "DRAFT",
      open: "OPEN",
      onTrack: "ON TRACK",
      completed: "COMPLETED",
      upcoming: "UPCOMING",
      behind: "BEHIND",
      archived: "ARCHIVED"
    },
    timeline: {
      numberOfDays: 180
    },
    milestone: {
      status: {
        open: "OPEN",
        completed: "COMPLETED",
        inComplete: "INCOMPLETE",
        inProgress: "INPROGRESS"
      },
      type: {
        start: "START",
        standard: "STANDARD",
        end: "END"
      }
    },
    members: {
      actions: {
        editEntities: "EDIT_ENTITIES",
        remove: "REMOVE"
      }
    },
    document: {
      status: {
        none: "NONE",
        draft: "DRAFT",
        review: "REVIEW",
        final: "FINAL",
        signed: "SIGNED",
        archived: "ARCHIVED",
        deleted: "DELETED",
        uploaded: "UPLOADED"
      },
      maxSize: 53687091200,
      editExcelDocumentPage: {
        columnMultiplier: 6.0,
        columnWidthContextMenu: 16,
        ptToPixel: 0.75 / 0.82
      }
    },
    risk: {
      status: {
        raised: "RAISED",
        inProgress: "IN-PROGRESS",
        closed: "CLOSED"
      },
      resolution: {
        fixed: "FIXED",
        wontFix: "WONT-FIX",
        duplicate: "DUPLICATE"
      }
    },
    queries: {
      status: {
        open: "OPEN",
        draft: "DRAFT",
        responded: "RESPONDED",
        closed: "CLOSED",
        note: "NOTE",
        attachment: "ATTACHMENT",
        error: "ERROR"
      },
      flags: {
        approved: "APPROVED",
        rejected: "REJECTED"
      },
      queryTypes: {
        dataRequest: "DATA_REQUEST",
        approveRequest: "APPROVE_REQUEST",
        todoRequest: "TODO_REQUEST",
        queryRequest: "QUERY_REQUEST",
        queryList: "QUERY_LIST",
        shortFormQuery: "SHORT_FORM_QUERY",
        reviewNote: "Review Note",
        workFlowRequest: "WORKFLOW_ACTIONS"
      },
      menu: {
        actionItem: "ACTIONS",
        webSheetButtons: "BUTTONS"
      },
      responses: {
        responseTypes: {
          message: "MESSAGE",
          approve: "APPROVE",
          reject: "REJECT",
          close: "CLOSE",
          reassign: "REASSIGN",
          reopen: "REOPEN"
        },
        attachmentsSizeLimit: 47185920 //45MB
      },
      fields: {
        assignTo: "assignTo",
        files: "files",
        requiredBy: "requiredBy",
        requestDetail: "requestDetail",
        description: "description"
      },
      events: {
        smartform: {
          refreshAnswers: {
            key: "REFRESH_SMARTFORM_ANSWERS",
            type: "answers"
          },
          refreshMetadata: {
            key: "REFRESH_SMARTFORM_METADATA",
            type: "metadata"
          }
        }
      },
      workflow: {
        actionTypes: {
          workflow: "WORKFLOW",
          legacy: "LEGACY"
        },
        availableWhen: "availableWhen",
        availableTo: "availableTo"
      }
    },
    crudFormMode: {
      create: "CREATE",
      update: "UPDATE"
    }
  },
  virtualScroll: {
    searchPage: {
      itemHeight: 75,
      visibleItems: 10,
      tolerance: 50
    },
    clientProjectDocumentsPage: {
      itemHeight: 55,
      visibleItems: 12,
      tolerance: 50
    }
  },
  logout: {
    resetApp: "RESET_APP"
  },
  pubsub: {
    added: "ADDED",
    updated: "UPDATED",
    deleted: "DELETED"
  },
  pill: {
    type: {
      outline: "outline",
      filled: "filled"
    }
  },
  insight: {
    events: {
      REFRESH_INSIGHTS_BOARD: "REFRESH_INSIGHTS_BOARD"
    },
    options: {
      multiFilter: "MULTI_FILTER",
      sort: "SORT"
    },
    levels: {
      engagementType: "ENGAGEMENT_TYPE"
    }
  },
  fileAttachmentItemType: {
    document: "document",
    websheet: "websheet"
  },
  generatedBy: {
    system: "SYSTEM",
    user: "USER"
  }
};
